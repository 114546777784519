<script setup>

import {ref} from "vue";
import {router} from "@inertiajs/vue3";

const props = defineProps({
    isOpen: Boolean,
    project: {
        type: Object,
        default: {}
    }
})
const emit = defineEmits([
    'close',
])
const deleteProjectLoading = ref(false)

function deleteProject() {
    deleteProjectLoading.value = true
    router.delete(route('projects.details.delete', props.project.id), {
        onSuccess: () => emit('close'),
        onFinish: () => deleteProjectLoading.value = false
    })
    deleteProjectLoading.value = false
}
</script>

<template>
    <v-dialog
        :model-value="isOpen"
        :persistent="true"
        width="auto"
    >
        <v-card>
            <v-card-title class="text-h5">
                {{ project.name }} löschen?
            </v-card-title>
            <v-card-text>
                Möchten Sie {{ project.name }} wirklich löschen?<br><b>Dieser Vorgang kann nicht
                rückgängig gemacht werden!</b>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    :disabled="deleteProjectLoading"
                    @click="$emit('close')"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    color="red-darken-1"
                    :loading="deleteProjectLoading"
                    :disabled="deleteProjectLoading"
                    @click="deleteProject"
                >
                    Löschen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
