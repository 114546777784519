import {createApp, h} from 'vue';
import {createInertiaApp, usePage} from '@inertiajs/vue3';
import {ZiggyVue} from '/vendor/tightenco/ziggy';
import Vuetify from "@/vuetify.js";
import VuetifyTiptap from "@/tiptap.js";
import Toast from 'vue-toastification';
import timeago from 'vue-timeago3';
import mitt from "mitt";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from "@bugsnag/browser-performance";

import 'vue-toastification/dist/index.css';
import './bootstrap';
import '../css/app.css';

const eventBus = mitt()

const bugsnagConfig = {
    apiKey: import.meta.env.VITE_BUGSNAG_JS_API_KEY,
    appVersion: import.meta.env.VITE_BUGSNAG_APP_VERSION,
    enabledReleaseStages: ['production']
}

BugsnagPerformance.start(bugsnagConfig)
Bugsnag.start({
    ...bugsnagConfig,
    plugins: [new BugsnagPluginVue()],
})
const bugsnagVue = Bugsnag.getPlugin('vue')

createInertiaApp({
    title: (title) => `${title} - ${usePage().props?.branding?.company_name ?? 'joiny.'}`,
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', {eager: true})
        return pages[`./Pages/${name}.vue`]
    },
    setup({el, App, props, plugin}) {
        const vueApp = createApp({render: () => h(App, props)})
            .use(bugsnagVue)
            .use(plugin)
            .use(Vuetify)
            .use(VuetifyTiptap)
            .use(ZiggyVue)
            .use(Toast, {timeout: 5000})
            .use(timeago)
        vueApp.config.globalProperties.$route = route
        vueApp.config.globalProperties.window = window
        vueApp.config.unwrapInjectedRef = true
        vueApp.provide('eventBus', eventBus)

        return vueApp.mount(el);
    },
    progress: {
        color: '#ffe000',
    },
});
