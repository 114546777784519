<script setup>
import Default from "@/Layouts/Default.vue";
import Wrapper from "@/Pages/Console/Settings/Wrapper.vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import {computed, ref} from "vue";
import {validationRules} from "@/helper.js";

defineOptions({layout: [Default, Wrapper]})
const props = defineProps({
    roles: Object
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const form = useForm({
    id: undefined,
    name: undefined
})

const rolesHeader = [
    {title: 'Name', key: 'name'},
    {title: 'Mitarbeitende', key: 'number_of_users'},
    {title: 'Berechtigungen', key: 'number_of_permissions'},
    {key: 'actions', align: 'end'},
]

const rolePermissionGroups = [
    {
        label: 'Community',
        key: 'access_community',
        permissions: [
            {key: 'create_community_post', label: 'Posts erstellen'},
        ]
    },
    {
        label: 'Kunden',
        key: 'access_customers',
        permissions: [
            {key: 'access_customers_all', label: 'alle Kunden'},
            {key: 'access_customers_settings', label: 'Einstellungen'},
            {key: 'create_customers', label: 'erstellen'},
        ]
    },
    {
        label: 'Projekte',
        key: 'access_projects',
        permissions: [
            {key: 'access_projects_all', label: 'alle Projekte'},
            {key: 'create_projects', label: 'erstellen'},
            {key: 'update_projects', label: 'bearbeiten'},
            {key: 'delete_projects', label: 'löschen'},
        ]
    },
    {
        label: 'Onboardings',
        key: 'access_onboardings',
        permissions: []
    },
    {
        label: 'Zahlung',
        key: 'access_payments',
        permissions: []
    },
    {
        label: 'Dateien',
        key: 'access_files',
        permissions: []
    },
    {
        label: 'Einstellungen',
        key: 'access_settings',
        permissions: []
    }
];

const rolesExpanded = ref([])
const roleDialog = ref(false)

function updateRole(role, permissionName, value, onSuccess = undefined) {
    form.processing = true

    router.patch(route('settings.roles.update', {role: role.id}), {
        [permissionName]: value
    }, {
        onSuccess,
        onFinish: () => form.processing = false
    })
}

function saveRole() {
    if (form.id) {
        form.patch(route('settings.roles.update', {role: form.id}), {
            onSuccess: () => {
                roleDialog.value = false
                form.reset()
            }
        })
    } else {
        form.post(route('settings.roles.store'), {
            onSuccess: () => {
                roleDialog.value = false
                form.reset()
            }
        })
    }
}
</script>

<template>
    <v-row>
        <v-col>
            <v-card class="pa-5">
                <div class="d-flex">
                    <div>
                        <h3>Rollen für Mitarbeitende</h3>
                    </div>

                    <v-spacer/>

                    <v-btn
                        prepend-icon="mdi-plus"
                        color="primary"
                        text="Rolle"
                        @click="roleDialog = true"
                    />
                </div>

                <v-data-table
                    v-model:expanded="rolesExpanded"
                    :headers="rolesHeader"
                    :items="roles.data"
                    item-value="id"
                    show-expand
                >
                    <template #item.name="{ item }">
                        <span class="font-weight-bold">{{ item.name }}</span>
                    </template>

                    <template #item.number_of_permissions="{ item }">
                        {{ item.number_of_active_permissions }} von {{ item.number_of_total_permissions }}
                    </template>

                    <template #item.actions="{ item }">
                        <div class="d-flex ga-1 justify-end">
                            <v-btn
                                v-if="item.company_id !== null"
                                icon="mdi-pencil-outline"
                                variant="text"
                                @click="Object.assign(form, item); roleDialog = true"
                            />

                            <!--                            <v-btn-->
                            <!--                                icon="mdi-trash-can-outline"-->
                            <!--                                color="red"-->
                            <!--                                variant="text"-->
                            <!--                            />-->
                        </div>
                    </template>

                    <template #expanded-row="{ columns, item }">
                        <tr
                            v-for="rolePermissionGroup in rolePermissionGroups"
                            :key="rolePermissionGroup.key"
                        >
                            <td :colspan="columns.length">
                                <div class="py-3">
                                    <v-row class="pt-3">
                                        <v-col cols="12">
                                            <v-switch
                                                :model-value="item.permissions.includes(rolePermissionGroup.key)"
                                                class="user-role-permission-switch"
                                                :label="rolePermissionGroup.label"
                                                :disabled="user.roles.map(role => role.id).includes(item.id) || rolePermissionGroup.key === 'access_community'"
                                                density="compact"
                                                color="primary"
                                                hide-details
                                                flat
                                                @update:model-value="value => updateRole(item, rolePermissionGroup.key, value)"
                                            />
                                        </v-col>

                                        <v-col
                                            v-for="rolePermission in rolePermissionGroup.permissions"
                                            :key="rolePermission.key"
                                            cols="auto"
                                        >
                                            <v-switch
                                                :model-value="item.permissions.includes(rolePermission.key)"
                                                class="user-role-permission-switch"
                                                :label="rolePermission.label"
                                                :disabled="!item.permissions.includes(rolePermissionGroup.key) || user.roles.map(role => role.id).includes(item.id)"
                                                density="compact"
                                                color="primary"
                                                hide-details
                                                flat
                                                @update:model-value="value => updateRole(item, rolePermission.key, value)"
                                            />
                                        </v-col>
                                    </v-row>
                                </div>
                            </td>
                        </tr>
                    </template>

                    <template #bottom/>
                </v-data-table>
            </v-card>
        </v-col>

        <v-col>

        </v-col>
    </v-row>

    <!-- Role Dialog -->
    <v-dialog
        v-model="roleDialog"
        width="450"
    >
        <v-form
            validate-on="submit"
            @submit.prevent="saveRole"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Rolle {{ form.id ? 'ändern' : 'hinzufügen' }}</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="form.name"
                        variant="outlined"
                        label="Name"
                        :error-messages="form.errors.name"
                        hide-details="auto"
                        autofocus
                        :rules="[validationRules.required]"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        :disabled="form.processing"
                        @click="roleDialog = false; form.reset()"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="primary"
                        :loading="form.processing"
                        type="submit"
                    >
                        {{ form.id ? 'Ändern' : 'Hinzufügen' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<style scoped>

.user-role-permission-switch:deep(.v-label) {
    font-size: 0.85rem;
    padding-inline-start: 16px;
    color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
    opacity: inherit;
}

</style>
