<script setup>
import Default from "@/Layouts/Default.vue";
import Wrapper from "@/Pages/Console/Chat/Wrapper.vue";
import {router, useForm} from "@inertiajs/vue3";

defineOptions({layout: [Default, Wrapper]})
const props = defineProps({
    settings: Object
})

const form = useForm({
    integration_telegram_bot_token: props.settings?.integration_telegram_bot_token
})

async function saveSettings() {
    form.post(route('chat.settings.save'))
}

function initFacebookSdk() {
    window.fbAsyncInit = function () {
        FB.init({
            appId: '1037680054089421',
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v19.0'
        });
    };

    // load facebook sdk script
    (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        const js = d.createElement(s)
        js.id = id
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
}

function launchWhatsAppSignup() {
    // Launch Facebook login
    FB.login(function (response) {
        if (response.authResponse) {
            const code = response.authResponse.code;
            //Use this token to call the debug_token API and get the shared WABA's ID
            router.post(route('chat.verify-whatsapp-code'), {
                code
            })
        } else {
            console.log('User cancelled login or did not fully authorize.');
        }
    }, {
        config_id: '735280548483610', // configuration ID obtained in the previous step goes here
        response_type: 'code',     // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: {
            setup: {}
        }
    });
}

async function emailGenerateAuthUrl() {
    // const redirectUrl = new URL(window.location.href)
    // redirectUrl.searchParams.set('channel', 'nylas')
    //
    // window.location.href = (await httpsCallable(functions, 'chat-channels-email-generateAuthUrl')({
    //     emailAddress: emailAddress.value,
    //     successUrl: redirectUrl.toString()
    // })).data as string
}

// onMounted(async () => {
//     if (route.query.code && route.query.channel === 'nylas') {
//         console.log(await httpsCallable(functions, 'chat-channels-email-exchangeMailboxToken')({
//             token: route.query.code
//         }))
//     }
// })

initFacebookSdk()
</script>

<template>
    <v-row class="flex-grow-0">
        <v-col>
            <v-row>
                <v-col>
                    <h2>Integrationen</h2>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-row>
                        <v-col>
                            <h3>Telegram</h3>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="form.integration_telegram_bot_token"
                                variant="outlined"
                                label="Bot Token"
                                hint="Bot Token, welcher mit dem @BotFather erstellt wurde."
                                :persistent-hint="true"
                                :disabled="form.processing"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-row>
                        <v-col>
                            <h3>WhatsApp</h3>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <v-btn
                                prepend-icon="mdi-whatsapp"
                                color="green"
                                @click="launchWhatsAppSignup"
                            >
                                WhatsApp Business verbinden
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-row>
                        <v-col>
                            <h3>E-Mail</h3>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                variant="outlined"
                                label="E-Mail Adresse"
                                :disabled="form.processing"
                            />

                            <v-btn
                                @click="emailGenerateAuthUrl"
                            >
                                E-Mail verbinden
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col class="text-end">
                    <v-btn
                        color="primary"
                        prepend-icon="mdi-content-save-outline"
                        :loading="form.processing"
                        @click="saveSettings"
                    >
                        Speichern
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>
