<script setup>
import {ref} from "vue";
import {validationRules} from "@/helper.js";
import {useForm} from "@inertiajs/vue3";

const props = defineProps({
    isOpen: Boolean,
    customer: {
        type: Object,
        default: {}
    }
})
const emit = defineEmits([
    'abort-clicked',
    'close',
])

const form = useForm({
    id: props.customer.id,
    name: props.customer.name,
    phone_number: props.customer.phone_number,
    fax_number: props.customer.fax_number,
    email: props.customer.email,
    tax_number: props.customer.tax_number,
    website: props.customer.website,
    logo: props.customer.logo,
    address_street: props.customer.address_street,
    address_street_number: props.customer.address_street_number,
    address_zip: props.customer.address_zip,
    address_city: props.customer.address_city,
    contact_firstname: props.customer.contact_firstname,
    contact_lastname: props.customer.contact_lastname,
    contact_phone_number: props.customer.contact_phone_number,
    contact_email: props.customer.contact_email,
    notes: props.customer.notes
})
const isFormValid = ref()
const customerForm = ref()

async function submit() {
    await customerForm.value.validate()
    if (!isFormValid.value) return

    if (form.id) {
        form.post(route('customers.update', { customer: form.id }), {
            onSuccess: () => resetAndClose()
        })
    } else {
        form.post(route('customers.create'), {
            onSuccess: () => resetAndClose()
        })
    }
}

function resetAndClose() {
    emit('close')
}
</script>

<template>
    <v-dialog
        :model-value="isOpen"
        max-width="1000"
        persistent
    >
        <v-form
            v-model="isFormValid"
            ref="customerForm"
            validate-on="none"
            @submit.prevent="submit"
        >
            <v-card
                :title="form.id ? form.name : 'Neuer Kunde'"
            >
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-row>
                                <v-col>
                                    <h4>Allgemein</h4>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.name"
                                        label="Name"
                                        type="text"
                                        variant="outlined"
                                        :rules="[validationRules.required]"
                                    />

                                    <v-text-field
                                        v-model="form.phone_number"
                                        label="Telefon"
                                        type="tel"
                                        variant="outlined"
                                    />

                                    <v-text-field
                                        v-model="form.fax_number"
                                        label="Fax"
                                        type="tel"
                                        variant="outlined"
                                    />

                                    <v-text-field
                                        v-model="form.email"
                                        label="E-Mail"
                                        type="email"
                                        variant="outlined"
                                        :rules="[validationRules.email]"
                                    />
                                </v-col>

                                <v-col>
                                    <v-text-field
                                        v-model="form.tax_number"
                                        label="Umsatzsteuer-IdNr."
                                        type="text"
                                        variant="outlined"
                                        :rules="[validationRules.taxId]"
                                    />

                                    <v-text-field
                                        v-model="form.website"
                                        prefix="https://"
                                        label="Webseite"
                                        type="text"
                                        variant="outlined"
                                        :rules="[validationRules.domain]"
                                        @paste.prevent="event => form.website = event.clipboardData.getData('text/plain').replace(/^https?:\/\//, '')"
                                    />

                                    <v-file-input
                                        @change="event => form.logo = event.target.files[0]"
                                        @click:clear="() => form.logo = undefined"
                                        accept="image/jpeg, image/png"
                                        variant="outlined"
                                        label="Logo"
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <h4>Anschrift</h4>
                                </v-col>

                                <v-col>
                                    <h4>Ansprechpartner</h4>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-row>
                                        <v-col
                                            cols="7"
                                            class="pb-0"
                                        >
                                            <v-text-field
                                                v-model="form.address_street"
                                                label="Straße"
                                                type="text"
                                                variant="outlined"
                                            />
                                        </v-col>

                                        <v-col
                                            cols="5"
                                            class="pb-0"
                                        >
                                            <v-text-field
                                                v-model="form.address_street_number"
                                                label="Hausnummer"
                                                type="text"
                                                variant="outlined"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="3"
                                            class="py-0"
                                        >
                                            <v-text-field
                                                v-model="form.address_zip"
                                                label="PLZ"
                                                type="text"
                                                variant="outlined"
                                            />
                                        </v-col>

                                        <v-col
                                            cols="9"
                                            class="py-0"
                                        >
                                            <v-text-field
                                                v-model="form.address_city"
                                                label="Ort"
                                                type="text"
                                                variant="outlined"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col>
                                    <v-row>
                                        <v-col
                                            class="pb-0"
                                        >
                                            <v-text-field
                                                v-model="form.contact_firstname"
                                                label="Vorname"
                                                type="text"
                                                variant="outlined"
                                            />
                                        </v-col>

                                        <v-col
                                            class="pb-0"
                                        >
                                            <v-text-field
                                                v-model="form.contact_lastname"
                                                label="Nachname"
                                                type="text"
                                                variant="outlined"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            class="py-0"
                                        >
                                            <v-text-field
                                                v-model="form.contact_phone_number"
                                                label="Telefonnummer"
                                                type="tel"
                                                variant="outlined"
                                            />
                                        </v-col>

                                        <v-col
                                            class="py-0"
                                        >
                                            <v-text-field
                                                v-model="form.contact_email"
                                                label="E-Mail-Adresse"
                                                type="email"
                                                variant="outlined"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <h4>Notizen</h4>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-textarea
                                        v-model="form.notes"
                                        variant="outlined"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        text="Abbrechen"
                        :disabled="form.processing"
                        @click="$emit('abort-clicked')"
                    />
                    <v-btn
                        class="text-none"
                        text="Speichern"
                        color="primary"
                        type="submit"
                        :disabled="form.processing"
                        :loading="form.processing"
                    />
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
