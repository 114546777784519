<script setup>
import Default from "@/Layouts/Default.vue";
import {Head, router, useForm, usePage} from "@inertiajs/vue3";
import NewEditCustomerDialog from "@/Components/Customers/NewEditCustomerDialog.vue";
import {computed, inject, ref} from "vue";
import UserAvatar from "@/Components/UserAvatar.vue";
import {validationRules} from "@/helper.js";

defineOptions({layout: Default})
const props = defineProps({
    customer: Object,
    team: Array,
    projects: Array,
    offers: Array,
    invoices: Array,
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const customerUserForm = useForm({
    id: undefined,
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    disabled: undefined,
})

const membersForm = useForm({
    members: props.customer.members.map(member => member.id)
})

const removeCustomerUserDialog = ref(false)
const isNewEditCustomerDialogOpen = ref(false)
const enableDisableCustomerUserDialog = ref(false)
const addCustomerUserDialog = ref(false)
const isCustomerUserFormValid = ref(false)
const customerUserFormElement = ref()

const invoiceAmountPaid = 0
const invoiceAmountDue = 0

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {
    title: props.customer.name,
    back: route('customers')
})

async function addCustomerUser() {
    customerUserForm.clearErrors();

    await customerUserFormElement.value.validate()
    if (!isCustomerUserFormValid.value) return

    customerUserForm.post(route('customers.details.invite-customer-user', {customer: props.customer.id}), {
        onSuccess: () => {
            addCustomerUserDialog.value = false
            customerUserForm.reset()
        }
    })
}

function updateCustomerUserDisabledStatus() {
    customerUserForm.patch(route('customers.details.customer-user.disable', {
        customer: props.customer.id,
        user: customerUserForm.id
    }), {
        onSuccess: () => {
            enableDisableCustomerUserDialog.value = false
            customerUserForm.reset()
        }
    })
}

function updateMembers(isModalOpen) {
    membersForm.patch(route('customers.details.update-members', {customer: props.customer.id}), {
        onSuccess: () => isModalOpen.value = false
    })
}
</script>

<template>
    <Head :title="`${props.customer.name} - Kunden`"/>

    <v-container
        :fluid="true"
        class="h-100 d-flex flex-column flex-nowrap"
    >
        <v-row class="flex-grow-0">
            <v-col>
                <v-row align="center">
                    <v-col cols="auto" v-if="customer?.logo_url">
                        <img
                            :src="customer.logo_url"
                            style="height: 80px; object-fit: contain"
                            alt=""
                        >
                    </v-col>

                    <v-col>
                        <v-row>
                            <v-col>
                                <div class="d-flex flex-column">
                                    <a
                                        v-if="customer?.website"
                                        :href="customer?.website"
                                        target="_blank"
                                        style="text-decoration: none; color: inherit"
                                    >
                                        <h2>{{ customer?.name }}</h2>
                                    </a>
                                    <h2 v-else>{{ customer?.name }}</h2>
                                    <span>Kunde {{ customer?.number }}</span>
                                    <span v-if="customer?.tax_number">USt-IdNr {{ customer?.tax_number }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col
                        v-if="user.permissions.includes('update_customers')"
                        cols="auto"
                    >
                        <v-btn
                            icon="mdi-pencil"
                            variant="plain"
                            @click="isNewEditCustomerDialogOpen = true"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col v-if="customer?.address_street || customer?.address_zip">
                        <a
                            :href="`https://maps.google.com/?q=${customer?.address_street} ${customer?.address_street_number}, ${customer?.address_zip} ${customer?.address_city}`"
                            target="_blank"
                            style="text-decoration: none; color: inherit"
                        >
                            <h4>Rechnungsanschrift</h4>
                            <p>{{ customer?.address_street }} {{ customer?.address_street_number }}</p>
                            <p>{{ customer?.address_zip }} {{ customer?.address_city }}</p>
                        </a>
                    </v-col>

                    <v-col v-if="customer?.phone_number || customer?.fax_number || customer?.email">
                        <h4>Kontaktdaten</h4>
                        <p v-if="customer?.phone_number">
                            <a
                                :href="`tel:${customer?.phone_number}`"
                                style="color: inherit"
                            >
                                <v-icon icon="mdi-phone-outline"/>
                                {{ customer?.phone_number }}
                            </a>
                        </p>
                        <p v-if="customer?.fax_number">
                            <a
                                :href="`fax:${customer?.fax_number}`"
                                style="color: inherit"
                            >
                                <v-icon icon="mdi-fax"/>
                                {{ customer?.fax_number }}
                            </a>
                        </p>
                        <p v-if="customer?.email">
                            <a
                                :href="`mailto:${customer?.email}`"
                                style="color: inherit"
                            >
                                <v-icon icon="mdi-email-outline"/>
                                {{ customer?.email }}
                            </a>
                        </p>
                    </v-col>

                    <v-col v-if="customer?.contact_firstname">
                        <h4>Ansprechpartner</h4>
                        <p>
                            <v-icon icon="mdi-account-outline"/>
                            {{ customer?.contact_firstname }}
                            {{ customer?.contact_lastname }}
                        </p>
                        <p v-if="customer?.contact_phone_number">
                            <a
                                :href="`tel:${customer?.contact_phone_number}`"
                                style="text-decoration: none; color: inherit"
                            >
                                <v-icon icon="mdi-phone-outline"/>
                                {{ customer?.contact_phone_number }}
                            </a>
                        </p>
                        <p v-if="customer?.contact_email">
                            <a
                                :href="`mailto:${customer?.contact_email}`"
                                style="text-decoration: none; color: inherit"
                            >
                                <v-icon icon="mdi-email-outline"/>
                                {{ customer?.contact_email }}
                            </a>
                        </p>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col v-if="customer?.notes">
                        <h4>Notizen</h4>
                        <p>{{ customer?.notes }}</p>
                    </v-col>

                    <v-col>
                        <h4>Team</h4>
                        <div class="d-flex ga-1">
                            <UserAvatar
                                v-for="member in customer?.members"
                                :key="member.id"
                                :user="{...member}"
                            />

                            <v-dialog
                                v-if="user.permissions.includes('update_customers')"
                                width="450"
                            >
                                <template #activator="{ props: activatorProps }">
                                    <v-btn
                                        :icon="true"
                                        variant="text"
                                        v-bind="activatorProps"
                                        @click=""
                                    >
                                        <v-avatar
                                            icon="mdi-account-edit"
                                            size="large"
                                            style="border: 1px grey dashed"
                                        />
                                        <v-tooltip
                                            activator="parent"
                                            location="bottom"
                                            text="Teammitglieder bearbeiten"
                                        />
                                    </v-btn>
                                </template>

                                <template #default="{ isActive }">
                                    <v-card>
                                        <v-card-title>
                                            <span class="text-h5">Teammitglieder bearbeiten</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-autocomplete
                                                v-model="membersForm.members"
                                                :multiple="true"
                                                :chips="true"
                                                :items="team.map(member => { return { title: `${member.firstname} ${member.lastname}`, value: member.id } })"
                                                variant="outlined"
                                                label="Mitglieder"
                                            >
                                            </v-autocomplete>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn
                                                text="Abbrechen"
                                                :disabled="membersForm.processing"
                                                @click="isActive.value = false"
                                            />
                                            <v-btn
                                                color="primary"
                                                text="Speichern"
                                                :loading="membersForm.processing"
                                                :disabled="membersForm.processing"
                                                @click="updateMembers(isActive)"
                                            />
                                        </v-card-actions>
                                    </v-card>
                                </template>
                            </v-dialog>
                        </div>
                    </v-col>
                </v-row>
            </v-col>

            <v-col>
                <div v-if="page.props.features.customers.accounts._active">
                    <div class="d-flex flex-align">
                        <h3>Kundenzugänge</h3>
                        <v-spacer/>
                        <v-btn
                            prepend-icon="mdi-plus"
                            color="primary"
                            text="Kundenzugang"
                            @click="addCustomerUserDialog = true"
                        />
                    </div>

                    <v-table
                        class="bg-transparent"
                        :hover="true"
                    >
                        <thead>
                        <tr>
                            <th class="font-weight-bold">Name</th>
                            <th class="font-weight-bold text-center">E-Mail</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="customerUser in customer.users"
                            :key="customerUser.id"
                        >
                            <td>
                                <div class="d-flex ga-3 align-center"
                                     :style="{ 'opacity': customerUser.disabled ? 0.5 : 1.0 }">
                                    <UserAvatar
                                        :user="customerUser"
                                        size="small"
                                        :disable-tooltip="true"
                                    />
                                    <div>
                                        {{ customerUser.firstname }} {{ customerUser.lastname }}
                                    </div>
                                </div>
                            </td>
                            <td class="text-center"><span :style="{ 'opacity': customerUser.disabled ? 0.5 : 1.0 }">{{
                                    customerUser.email
                                }}</span></td>
                            <td class="d-flex align-center justify-end">
                                <v-icon
                                    v-if="!customerUser.disabled"
                                    icon="mdi-account-cancel-outline"
                                    color="red"
                                    @click="Object.assign(customerUserForm, customerUser); customerUserForm.disabled = true; enableDisableCustomerUserDialog = true"
                                />

                                <v-icon
                                    v-if="customerUser.disabled"
                                    icon="mdi-account-check-outline"
                                    color="green"
                                    @click="Object.assign(customerUserForm, customerUser); customerUserForm.disabled = false; enableDisableCustomerUserDialog = true"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </v-table>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
                class="d-flex flex-column"
            >
                <h2>Dokumente</h2>
            </v-col>
        </v-row>

        <v-row class="d-flex">
            <v-col cols="6" class="d-flex flex-column">
                <h4>Angebote</h4>

                <div
                    v-if="!offers.length"
                    class="d-flex flex-column align-center ga-3 mt-5"
                >
                    <span>Noch keine Angebote 😅</span>
                    <div>
                        <v-btn
                            prepend-icon="mdi-plus"
                            text="Angebot"
                            color="primary"
                            @click="router.visit(route('payments.new', {type: 'offer', customer: customer.id}))"
                        />
                    </div>
                </div>

                <v-list
                    v-if="offers.length"
                    lines="two"
                    style="background: none"
                    class="d-flex flex-column flex-grow-1"
                >
                    <v-list-item
                        v-for="offer in offers"
                        :key="offer.id"
                        style="background-color: white"
                        @click="router.visit(route('payments.details', {paymentDocument: offer.id}))"
                    >
                        <template #prepend>
                            <v-avatar
                                icon="mdi-handshake-outline"
                                variant="tonal"
                            />
                        </template>

                        <template #title>
                            {{ offer.number || 'Entwurf' }}
                        </template>

                        <template #subtitle>
                            {{
                                new Date(offer.date).toLocaleDateString(undefined, {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                })
                            }}
                        </template>

                        <template #append>
                            <div class="d-flex ga-1">
                                <v-chip
                                    :text="(offer.total_amount / 100)?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }) + ' €'"
                                />

                                <v-chip
                                    v-if="offer.status === 'draft'"
                                    text="Entwurf"
                                />
                                <v-chip
                                    v-if="offer.status === 'sent'"
                                    color="green"
                                    text="Versandt"
                                />
                            </div>
                        </template>
                    </v-list-item>
                </v-list>
            </v-col>

            <v-col cols="6" class="d-flex flex-column">
                <h4>Rechnungen</h4>

                <div
                    v-if="!invoices.length"
                    class="d-flex flex-column align-center ga-3 mt-5"
                >
                    <span>Noch keine Rechnungen 😅</span>
                    <div>
                        <v-btn
                            prepend-icon="mdi-plus"
                            text="Rechnung"
                            color="primary"
                            @click="router.visit(route('payments.new', {type: 'invoice', customer: customer.id}))"
                        />
                    </div>
                </div>

                <v-list
                    v-if="invoices.length"
                    lines="two"
                    style="background: none"
                    class="d-flex flex-column flex-grow-1"
                >
                    <v-list-item
                        v-for="invoice in invoices"
                        :key="invoice.id"
                        style="background-color: white"
                        @click="router.visit(route('payments.details', {paymentDocument: invoice.id}))"
                    >
                        <template #prepend>
                            <v-avatar
                                icon="mdi-invoice-text-outline"
                                variant="tonal"
                            />
                        </template>

                        <template #title>
                            {{ invoice.number || 'Entwurf' }}
                        </template>

                        <template #subtitle>
                            {{
                                new Date(invoice.date).toLocaleDateString(undefined, {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                })
                            }}
                        </template>

                        <template #append>
                            <div class="d-flex ga-1">
                                <v-chip
                                    :text="(invoice.total_amount / 100)?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }) + ' €'"
                                />

                                <v-chip
                                    v-if="invoice.status === 'sent'"
                                    color="orange"
                                    text="Versandt"
                                />
                                <v-chip
                                    v-if="invoice.status === 'paid'"
                                    color="green"
                                    text="Bezahlt"
                                />
                                <v-chip
                                    v-if="invoice.status === 'overdue'"
                                    color="red"
                                    text="Überfällig"
                                />
                            </div>
                        </template>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <h2>Aktive Projekte</h2>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <div
                    v-if="!projects.length"
                    class="d-flex flex-column align-center ga-3 mt-5"
                >
                    <span>Noch keine Projekte 😅</span>
                    <div>
                        <v-btn
                            prepend-icon="mdi-plus"
                            text="Projekt"
                            color="primary"
                            @click="router.visit(route('projects', {action: 'new', customer: customer.id}))"
                        />
                    </div>
                </div>

                <v-list
                    v-if="projects.length"
                    lines="two"
                    style="background: none"
                >
                    <v-list-item
                        v-for="project in projects"
                        :key="project.id"
                        style="background-color: white"
                        :title="project.name"
                        :subtitle="project?.customer?.name"
                        @click="router.visit(route('projects.details', {project: project.id}))"
                    >
                        <template #prepend>
                            <v-avatar
                                icon="mdi-folder-outline"
                                variant="tonal"
                            />
                        </template>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>

        <NewEditCustomerDialog
            :is-open="isNewEditCustomerDialogOpen"
            :customer="customer"
            @abort-clicked="isNewEditCustomerDialogOpen = false"
            @close="isNewEditCustomerDialogOpen = false"
        />

        <!-- Add Customer User Dialog -->
        <v-dialog
            v-model="addCustomerUserDialog"
            width="450"
        >
            <v-form
                v-model="isCustomerUserFormValid"
                ref="customerUserFormElement"
                :disabled="customerUserForm.processing"
                validate-on="submit"
                @submit.prevent="addCustomerUser"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Kundenzugang hinzufügen</span>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="customerUserForm.firstname"
                            variant="outlined"
                            label="Vorname"
                            :disabled="customerUserForm.processing"
                            :rules="[validationRules.required]"
                        />

                        <v-text-field
                            v-model="customerUserForm.lastname"
                            variant="outlined"
                            label="Nachname"
                            :disabled="customerUserForm.processing"
                            :rules="[validationRules.required]"
                        />

                        <v-text-field
                            v-model="customerUserForm.email"
                            variant="outlined"
                            label="E-Mail-Adresse"
                            :error-messages="customerUserForm.errors.email"
                            :disabled="customerUserForm.processing"
                            :rules="[validationRules.required, validationRules.email]"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                            :disabled="customerUserForm.processing"
                            @click="addCustomerUserDialog = false"
                        >
                            Abbrechen
                        </v-btn>
                        <v-btn
                            color="primary"
                            :loading="customerUserForm.processing"
                            type="submit"
                        >
                            Hinzufügen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <!-- Remove Customer User Dialog -->
        <v-dialog
            v-model="removeCustomerUserDialog"
            width="auto"
            max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ customerUserForm.firstname }} {{
                        customerUserForm.lastname
                    }} löschen?
                </v-card-title>
                <v-card-text>
                    <span>Möchten Sie "{{
                            customerUserForm.firstname
                        }} {{
                            customerUserForm.lastname
                        }}" wirklich löschen?

                        <br>"{{ customerUserForm.firstname }} {{
                            customerUserForm.lastname
                        }}" kann sich danach nicht mehr einloggen.
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        :disabled="customerUserForm.processing"
                        @click="customerUserForm.reset(); removeCustomerUserDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="red-darken-1"
                        :loading="customerUserForm.processing"
                        :disabled="customerUserForm.processing"
                        @click="removeCustomerUser"
                    >
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Disable Customer User Dialog -->
        <v-dialog
            v-model="enableDisableCustomerUserDialog"
            width="auto"
            max-width="600"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ customerUserForm.firstname }} {{
                        customerUserForm.lastname
                    }} {{ customerUserForm.disabled ? 'deaktivieren' : 'aktivieren' }}?
                </v-card-title>
                <v-card-text class="d-flex flex-column ga-3">
                    <span>Möchten Sie "{{
                            customerUserForm.firstname
                        }} {{
                            customerUserForm.lastname
                        }}" wirklich {{ customerUserForm.disabled ? 'deaktivieren' : 'aktivieren' }}?</span>
                    <span v-if="customerUserForm.disabled">
                        "{{ customerUserForm.firstname }} {{
                            customerUserForm.lastname
                        }}" kann sich danach nicht mehr einloggen, bis der Account wieder aktiviert wurde.
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        :disabled="customerUserForm.processing"
                        @click="customerUserForm.reset(); enableDisableCustomerUserDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        :color="customerUserForm.disabled ? 'red-darken-1' : 'green-darken-1'"
                        :loading="customerUserForm.processing"
                        :disabled="customerUserForm.processing"
                        @click="updateCustomerUserDisabledStatus"
                    >
                        {{ customerUserForm.disabled ? 'Deaktivieren' : 'Aktivieren' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style scoped>

</style>
