<script setup>
import {computed, inject, nextTick, ref} from "vue";
import {Head, router, useForm, usePage} from "@inertiajs/vue3";
import UserAvatar from "@/Components/UserAvatar.vue";
import {de} from "date-fns/locale";
import Default from "@/Layouts/Default.vue";

defineOptions({layout: Default})
const props = defineProps({
    onboarding: Object
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const commentForm = useForm({
    content: undefined
})

const newComment = ref(false)
const commentEditor = ref()

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {title: 'Onboarding'})

function getChecklistProgress(checklist) {
    return Math.ceil(checklist.items?.filter(t => t.done === true).length / checklist.items?.length * 100)
}

function setChecklistItemDone(checklistId, checklistItemId, isDone) {
    router.patch(
        route('onboardings.checklist-item-update', {
            onboarding: props.onboarding.id,
            checklist: checklistId,
            checklistItem: checklistItemId
        }),
        {
            done: isDone
        },
        {
            onSuccess: () => router.reload()
        }
    )
}

function createComment() {
    commentForm.post(route('onboardings.comment-create', {onboarding: props.onboarding.id}), {
        preserveScroll: true,
        onSuccess: () => {
            newComment.value = false
            commentForm.reset()
        }
    })
}
</script>

<template>
    <Head title="Onboarding"/>

    <v-container
        fluid
        style="max-width: 1200px"
    >
        <v-card v-if="onboarding?.task">
            <v-row>
                <v-col>
                    <v-container class="d-flex flex-column ga-3">
                        <div>
                            <h3 class="mb-3">
                                <v-icon
                                    icon="mdi-text"
                                    size="small"
                                />&nbsp;Beschreibung
                            </h3>

                            <div
                                style="white-space: pre-wrap"
                            >
                                <span class="description-view" v-html="onboarding.task.description"/>
                            </div>
                        </div>

                        <div
                            v-for="checklist in onboarding.task.checklists"
                            :key="checklist.id"
                            class="d-flex flex-column ga-1"
                        >
                            <div class="d-flex">
                                <h3 class="mb-3 align-center">
                                    <v-icon
                                        icon="mdi-checkbox-marked-outline"
                                        size="small"
                                    />&nbsp;{{ checklist.name }}
                                </h3>
                            </div>

                            <div
                                v-if="checklist.items?.length"
                                class="d-flex align-center"
                            >
                                      <span
                                          class="text-caption"
                                      >
                                        {{ getChecklistProgress(checklist) }}&nbsp;%
                                      </span>
                                <v-progress-linear
                                    :rounded="true"
                                    :model-value="getChecklistProgress(checklist)"
                                    color="primary"
                                />
                            </div>

                            <div
                                class="d-flex flex-column ga-3"
                            >
                                <div>
                                    <div
                                        v-for="item in checklist.items"
                                        :key="item.id"
                                        class="d-flex align-center ga-2"
                                    >
                                        <v-checkbox
                                            :model-value="item.done"
                                            class="flex-grow-0 flex-shrink-0"
                                            :hide-details="true"
                                            color="primary"
                                            density="compact"
                                            @update:modelValue="isDone => setChecklistItemDone(checklist.id, item.id, isDone)"
                                        />

                                        <span>{{ item.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h3 class="mb-3">
                                <v-icon
                                    icon="mdi-comment-outline"
                                    size="small"
                                />&nbsp;Kommentare
                            </h3>

                            <div class="d-flex ga-3 align-start">
                                <UserAvatar
                                    :user="user"
                                    size="small"
                                    disable-tooltip
                                />

                                <v-card
                                    v-if="!newComment"
                                    color="white"
                                    class="w-100 text-grey-darken-2"
                                    rounded="lg"
                                    border
                                    @click="newComment = true; nextTick(() => commentEditor.editor.commands.focus())"
                                >
                                    Schreiben Sie einen Kommentar...
                                </v-card>

                                <div
                                    v-if="newComment"
                                    class="d-flex flex-column ga-2"
                                >
                                    <VuetifyTiptap
                                        ref="commentEditor"
                                        v-model="commentForm.content"
                                        :min-height="200"
                                        :max-height="465"
                                        :rounded="false"
                                        hide-bubble
                                        output="html"
                                        :disabled="commentForm.processing"
                                        @keydown.esc.stop="newComment = false; commentForm.reset()"
                                    />

                                    <div class="d-flex ga-3">
                                        <v-btn
                                            text="Speichern"
                                            variant="tonal"
                                            :disabled="commentEditor?.editor.isEmpty"
                                            :loading="commentForm.processing"
                                            @click="createComment"
                                        />

                                        <v-btn
                                            text="Abbrechen"
                                            variant="text"
                                            :disabled="commentForm.processing"
                                            @click="newComment = false; commentForm.reset()"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex flex-column ga-3 mt-3">
                                <div
                                    class="d-flex ga-3 align-start"
                                    v-for="comment in onboarding.task.comments"
                                >
                                    <UserAvatar
                                        :user="comment.user"
                                        size="small"
                                        disable-tooltip
                                    />

                                    <div class="d-flex flex-column flex-grow-1">
                                        <div class="d-flex ga-2 align-center">
                                                    <span class="font-weight-bold">{{
                                                            comment.user.firstname
                                                        }}&nbsp;{{ comment.user.lastname }}</span>
                                            <span class="text-caption">
                                                        <timeago
                                                            class="text-caption text-grey-darken-2"
                                                            :datetime="new Date(comment.created_at)"
                                                            :locale="de"
                                                        />
                                                    </span>
                                        </div>

                                        <v-card
                                            color="white"
                                            class="w-100 comment-content"
                                            rounded="lg"
                                            border
                                            v-html="comment.content"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-container>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<style scoped>
.description-view:deep(li) {
    margin-left: 1em;
}

.comment-content {
    font-size: calc(100% - 10%);
}
</style>
