<script setup>
import MovingBackground from "@/Components/MovingBackground.vue";
import {useTheme} from "vuetify";
import {computed} from "vue";
import {usePage} from "@inertiajs/vue3";

const page = usePage()
const branding = computed(() => page.props.branding)

const theme = useTheme()
theme.themes.value.joiny.colors.primary = branding.value.color_primary
theme.themes.value.joiny.colors["on-primary"] = branding.value.color_on_primary
</script>

<template>
    <div
        class="d-flex flex-grow-1"
        :style="{ backgroundColor: branding.color_background ?? 'inherit' }"
    >
        <MovingBackground v-if="!branding.color_background"/>
        <slot/>
    </div>
</template>
