<script setup>
import {Head, router, useForm, usePage} from '@inertiajs/vue3';
import Default from "@/Layouts/Default.vue";
import {computed, inject, ref} from "vue";
import DeleteProjectDialog from "@/Components/Projects/DeleteProjectDialog.vue";
import NewEditProjectDialog from "@/Components/Projects/NewEditProjectDialog.vue";
import {Companies} from "@/Helper/exclusion-helper.js";
import TaskDetailsProjectDialog from "@/Components/Projects/TaskDetailsProjectDialog.vue";
import OnboardingDetailsDialog from "@/Components/CustomerOnboardings/OnboardingDetailsDialog.vue";

defineOptions({layout: Default})
const props = defineProps({
    onboardings: Array,
    onboardingTemplate: Object,
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const tableHeaders = ref([])

const isOnboardingTemplateDialogOpen = ref(false)

const isOnboardingDetailDialogOpen = ref(false)
const selectedOnboardingId = ref()
const selectedOnboarding = computed(() => {
    return props.onboardings.find(o => o.id === selectedOnboardingId.value)
})

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {title: 'Onboarding'})

createTableHeader()

function createTableHeader() {
    tableHeaders.value = [
        {title: 'Kunde', key: 'customer'}
    ]
}
</script>

<template>
    <Head title="Onboarding"/>

    <v-container
        :fluid="true"
        class="overflow-hidden h-100 d-flex flex-column flex-nowrap"
    >
        <v-row class="flex-grow-0 align-center">
            <v-col class="d-flex justify-end">
                <v-btn
                    icon="mdi-cog-outline"
                    variant="text"
                    density="compact"
                    @click="isOnboardingTemplateDialogOpen = true"
                />
            </v-col>
        </v-row>

        <v-container
            style="max-width: 1200px"
            class="h-100 d-flex flex-column flex-nowrap"
        >
            <v-row v-if="!onboardings?.length">
                <v-col class="d-flex flex-column flex-grow-1 align-center justify-center ga-3">
                    <h1>Noch keine Onboardings 😅</h1>
                    <img
                        :src="`/img/customer_onboardings/no_customer_onboarding.svg`"
                        style="height: 300px"
                        alt=""
                    >
                    <div v-if="false">
                        <v-btn
                            color="primary"
                            prepend-icon="mdi-plus"
                            text="Erstes Onboarding anlegen"
                            @click="isNewOnboardingDialogOpen = true"
                        />
                    </div>
                </v-col>
            </v-row>
            <v-card
                v-if="onboardings?.length"
                class="h-100 overflow-y-auto flex-grow-1"
            >
                <v-container
                    :fluid="true"
                    class="d-flex flex-column flex-nowrap"
                >
                    <v-row>
                        <v-col
                            style="overflow-y: scroll"
                        >
                            <v-data-table
                                :items="onboardings"
                                :items-per-page="0"
                                :headers="tableHeaders"
                                @click:row="(_, {item}) => {selectedOnboardingId = item.id; isOnboardingDetailDialogOpen = true}"
                            >
                                <template #item.customer="{ item }">
                                    <div class="d-flex ga-3">
                                        <v-avatar
                                            icon="mdi-airplane-takeoff"
                                            variant="tonal"
                                        />

                                        <div class="d-flex flex-column justify-center">
                                            <div>{{ item.customer.name }}</div>
                                        </div>
                                    </div>
                                </template>

                                <template #bottom/>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-container>

        <OnboardingDetailsDialog
            v-model="isOnboardingDetailDialogOpen"
            :onboarding="selectedOnboarding"
        />

        <OnboardingDetailsDialog
            v-model="isOnboardingTemplateDialogOpen"
            :onboarding="onboardingTemplate"
            is-template
        />

    </v-container>
</template>
