<script setup>
import {computed, nextTick, ref, watch} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import UserAvatar from "@/Components/UserAvatar.vue";
import {de} from "date-fns/locale";
import {Companies} from "@/Helper/exclusion-helper.js";

const props = defineProps({
    modelValue: Boolean,
    task: Object,
    project: Object,
})

const emit = defineEmits([
    'update:modelValue'
])

const page = usePage()
const user = computed(() => page.props.auth.user)

const taskForm = useForm({
    name: undefined,
    description: undefined,
    deadline: undefined,
    members: undefined
})

const commentForm = useForm({
    content: undefined
})

const checklistForm = useForm({
    name: undefined
})

const checklistItemForm = useForm({
    name: undefined
})

const isShowingDeadlineMenu = ref(false)
watch(isShowingDeadlineMenu, (newIsShowingDeadlineMenu) => {
    if (newIsShowingDeadlineMenu) {
        taskForm.deadline = props.task.deadline || getCurrentDeadlineDate()
    } else {
        taskForm.reset('deadline')
    }
})
const deleteTaskLoading = ref(false)

const isShowingDeadlineDateMenu = ref(false)

const isShowingNewChecklistMenu = ref(false)
const newChecklistTextField = ref()

const isChangingName = ref(false)
const nameTextField = ref()

const isChangingDescription = ref(false)
const descriptionEditor = ref()

const newChecklistItemTextField = ref()

const newComment = ref(false)
const commentEditor = ref()

const hasCreatedCommentModal = ref(false)

function getCurrentDeadlineDate() {
    let deadlineDate = new Date()
    deadlineDate.setHours(12)
    deadlineDate.toISOString().slice(0, 10)
    return deadlineDate
}

function getChecklistProgress(checklist) {
    return Math.ceil(checklist.items?.filter(t => t.done === true).length / checklist.items?.length * 100)
}

function saveTaskField(fieldName, onSuccess) {
    if (fieldName === 'members' && taskForm[fieldName].constructor !== Array) {
        taskForm.members = Array(taskForm.members)
    }

    taskForm.patch(
        route('projects.details.tasks.update', {project: props.task.project.id, task: props.task.id}),
        {
            only: [fieldName],
            onSuccess: () => {
                taskForm.reset(fieldName)
                router.reload()
                onSuccess()
            }
        }
    )
}

function addNewChecklist(onSuccess) {
    checklistForm.post(
        route('projects.details.tasks.checklist.create', {project: props.task.project.id, task: props.task.id}),
        {
            onSuccess: () => {
                checklistForm.reset()
                router.reload()
                onSuccess()
            }
        }
    )
}

function createChecklistItem(checklistId, onSuccess) {
    checklistItemForm.post(
        route('projects.details.tasks.checklist.create-item', {
            project: props.task.project.id,
            task: props.task.id,
            taskChecklist: checklistId
        }),
        {
            onSuccess: () => {
                checklistItemForm.reset()
                router.reload()
                onSuccess()
            }
        }
    )
}

function setChecklistItemDone(checklistId, checklistItemId, isDone) {
    router.patch(
        route('projects.details.tasks.checklist.item.update', {
            project: props.task.project.id,
            task: props.task.id,
            taskChecklist: checklistId,
            taskChecklistItem: checklistItemId
        }),
        {
            done: isDone
        },
        {
            onSuccess: () => router.reload()
        }
    )
}

function deleteChecklistItem(checklistId, checklistItemId) {
    router.delete(
        route('projects.details.tasks.checklist.item.delete', {
            project: props.task.project.id,
            task: props.task.id,
            taskChecklist: checklistId,
            taskChecklistItem: checklistItemId
        }),
        {
            onSuccess: () => router.reload()
        }
    )
}

function deleteChecklist(checklistId) {
    router.delete(
        route('projects.details.tasks.checklist.delete', {
            project: props.task.project.id,
            task: props.task.id,
            taskChecklist: checklistId
        }),
        {
            onSuccess: () => router.reload()
        }
    )
}

function editDeadline() {
}

function deleteTask() {
    deleteTaskLoading.value = true

    router.delete(route('projects.details.tasks.delete', {project: props.project.id, task: props.task.id}), {
        onSuccess: () => {
            emit('update:modelValue', false)
        },
        onFinish: () => deleteTaskLoading.value = false
    })
}

function createComment() {
    commentForm.post(route('projects.details.tasks.comment.create', {project: props.project.id, task: props.task.id}), {
        preserveScroll: true,
        onSuccess: () => {
            newComment.value = false
            commentForm.reset()
        }
    })
}

function startChangingDescription() {
    if (user.value.tenant === 'portal')
        return

    isChangingDescription.value = true
    taskForm.description = props.task.description
    nextTick(() => descriptionEditor.value.editor.commands.focus('end'))
}

function requestClose() {
    if ([Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.value.company.id)) {
        hasCreatedCommentModal.value = true
    } else {
        emit('update:modelValue', false)
    }
}
</script>

<template>
    <v-dialog
        :model-value="modelValue"
        @update:modelValue="newValue => $emit('update:modelValue', newValue)"
        max-width="1000px"
    >
        <template #default>
            <v-card v-if="task">
                <template #default>
                    <v-row>
                        <v-col>
                            <v-container class="d-flex flex-column ga-3">
                                <div>
                                    <h2
                                        v-if="!isChangingName"
                                        @click="isChangingName = true; taskForm.name = task.name; $nextTick(() => nameTextField.focus())"
                                    >
                                        {{ task.name }}
                                    </h2>
                                    <v-text-field
                                        v-show="isChangingName"
                                        ref="nameTextField"
                                        v-model="taskForm.name"
                                        class="title-text-field remove-padding"
                                        variant="plain"
                                        density="compact"
                                        :hide-details="true"
                                        @keydown.enter="saveTaskField('name', () => isChangingName = false)"
                                        @keydown.esc.stop="isChangingName = false; taskForm.reset('name')"
                                    >
                                        <template #append-inner>
                                            <v-label class="text-caption">
                                                <v-icon
                                                    icon="mdi-keyboard-return"
                                                    size="x-small"
                                                />&nbsp;zum Speichern
                                            </v-label>
                                        </template>
                                    </v-text-field>
                                    <span class="text-grey-darken-1">in Liste <b>{{ task.list?.name }}</b></span>
                                </div>

                                <div class="d-flex ga-3">
                                    <div
                                        v-if="task?.author"
                                        class="d-flex flex-column ga-2"
                                    >
                                        <span class="text-body-2 text-grey-darken-1 font-weight-bold">
                                            Autor
                                        </span>
                                        <span class="text-subtitle-2 d-flex ga-1">
                                            <UserAvatar
                                                :user="{...task.author}"
                                                size="30"
                                            />
                                        </span>
                                    </div>

                                    <div
                                        class="d-flex flex-column ga-2"
                                    >
                                        <span class="text-body-2 text-grey-darken-1 font-weight-bold">
                                            Erstellt
                                        </span>
                                        <span class="text-subtitle-2 flex-grow-1 align-center d-flex font-weight-bold">
                                            {{
                                                new Date(task.created_at).toLocaleString(undefined, {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: '2-digit'
                                                })
                                            }}
                                        </span>
                                    </div>

                                    <div
                                        v-if="task.deadline"
                                        class="d-flex flex-column ga-2"
                                    >
                                        <span class="text-body-2 text-grey-darken-1 font-weight-bold">
                                            Frist
                                        </span>
                                        <span class="text-subtitle-2 flex-grow-1 align-center d-flex font-weight-bold">
                                            {{
                                                new Date(task.deadline).toLocaleString(undefined, {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: '2-digit'
                                                })
                                            }}
                                        </span>
                                    </div>

                                    <div
                                        v-if="task?.members?.length && user.tenant !== 'portal'"
                                        class="d-flex flex-column ga-2"
                                    >
                                        <span class="text-body-2 text-grey-darken-1 font-weight-bold">
                                            {{[Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.company.id) ? 'Agenturmitglied' : 'Mitglieder'}}
                                        </span>
                                        <span class="text-subtitle-2 d-flex ga-1">
                                            <UserAvatar
                                                v-for="member in task.members"
                                                :key="member.id"
                                                :user="{...member}"
                                                size="30"
                                            />
                                        </span>
                                    </div>
                                </div>

                                <div>
                                    <h3 class="mb-3">
                                        <v-icon
                                            icon="mdi-text"
                                            size="small"
                                        />&nbsp;Beschreibung
                                    </h3>

                                    <div
                                        v-if="!isChangingDescription && task.description"
                                        :class="{ 'cursor-pointer': user.tenant !== 'portal' }"
                                        style="white-space: pre-wrap"
                                        @click="startChangingDescription"
                                    >
                                        <span class="description-view" v-html="task.description"/>
                                    </div>

                                    <v-btn
                                        v-if="!isChangingDescription && !task.description"
                                        class="w-100 justify-start align-start pt-5 text-none"
                                        style="min-height: 70px"
                                        text="Detaillierte Beschreibung hinzufügen ..."
                                        @click="isChangingDescription = true; $nextTick(() => descriptionEditor.editor.commands.focus())"
                                    />

                                    <div
                                        v-if="isChangingDescription"
                                        class="d-flex flex-column ga-3"
                                    >
                                        <VuetifyTiptap
                                            ref="descriptionEditor"
                                            v-model="taskForm.description"
                                            :min-height="200"
                                            :max-height="465"
                                            :rounded="false"
                                            hide-bubble
                                            output="html"
                                            :disabled="taskForm.processing"
                                            @keydown.esc.stop="isChangingDescription = false; taskForm.reset('description')"
                                        />

                                        <div class="d-flex ga-3">
                                            <v-btn
                                                text="Speichern"
                                                variant="tonal"
                                                :disabled="descriptionEditor?.editor.isEmpty"
                                                :loading="taskForm.processing"
                                                @click="saveTaskField('description', () => isChangingDescription = false)"
                                            />

                                            <v-btn
                                                text="Abbrechen"
                                                variant="text"
                                                :disabled="taskForm.processing"
                                                @click="isChangingDescription = false; taskForm.reset('description')"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-for="checklist in task.checklists"
                                    :key="checklist.id"
                                    class="d-flex flex-column ga-1"
                                >
                                    <div class="d-flex">
                                        <h3 class="mb-3 align-center">
                                            <v-icon
                                                icon="mdi-checkbox-marked-outline"
                                                size="small"
                                            />&nbsp;{{ checklist.name }}
                                        </h3>

                                        <v-spacer/>

                                        <v-menu>
                                            <template #activator="{props}">
                                                <v-btn
                                                    v-if="user.tenant !== 'portal'"
                                                    v-bind="props"
                                                    text="Löschen"
                                                    class="text-none justify-start"
                                                />
                                            </template>
                                            <v-list>
                                                <v-list-item>
                                                    <div>
                                                        <h4>Checkliste wirklich löschen?</h4>
                                                        <p>Dieser Vorgang kann nicht rückgängig gemacht werden!</p>
                                                    </div>
                                                    <v-btn
                                                        prepend-icon="mdi-trash-can-outline"
                                                        text="Ja, löschen!"
                                                        color="red"
                                                        @click="deleteChecklist(checklist.id)"
                                                    />
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>

                                    <div
                                        v-if="checklist.items?.length"
                                        class="d-flex align-center"
                                    >
                                        <span
                                            class="text-caption"
                                        >
                                            {{ getChecklistProgress(checklist) }}&nbsp;%
                                        </span>
                                        <v-progress-linear
                                            :rounded="true"
                                            :model-value="getChecklistProgress(checklist)"
                                            color="primary"
                                        />
                                    </div>

                                    <div
                                        class="d-flex flex-column ga-3"
                                    >
                                        <div>
                                            <div
                                                v-for="item in checklist.items"
                                                :key="item.id"
                                                class="d-flex align-center ga-2"
                                            >
                                                <v-checkbox
                                                    :model-value="item.done"
                                                    class="flex-grow-0 flex-shrink-0"
                                                    :hide-details="true"
                                                    color="primary"
                                                    density="compact"
                                                    @update:modelValue="isDone => setChecklistItemDone(checklist.id, item.id, isDone)"
                                                />

                                                <span>{{ item.name }}</span>

                                                <v-spacer/>

                                                <v-btn
                                                    v-if="user.tenant !== 'portal'"
                                                    icon="mdi-trash-can-outline"
                                                    variant="text"
                                                    size="small"
                                                    color="grey-darken-1"
                                                    @click="deleteChecklistItem(checklist.id, item.id)"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <v-btn
                                                v-if="user.tenant !== 'portal'"
                                                v-show="!checklist.isAddingChecklistTask"
                                                class="text-none"
                                                text="Element hinzufügen"
                                                @click="checklist.isAddingChecklistTask = true; $nextTick(() => newChecklistItemTextField[0].focus())"
                                            />

                                            <div
                                                v-show="checklist.isAddingChecklistTask"
                                            >
                                                <v-text-field
                                                    ref="newChecklistItemTextField"
                                                    v-model="checklistItemForm.name"
                                                    variant="outlined"
                                                    placeholder="Element hinzufügen"
                                                    density="compact"
                                                    :hide-details="true"
                                                    maxlength="255"
                                                    @keydown.enter="createChecklistItem(checklist.id, () => checklist.isAddingChecklistTask = false)"
                                                />

                                                <div class="d-flex align-center ga-1">
                                                    <v-btn
                                                        class="text-none"
                                                        text="Speichern"
                                                        color="primary"
                                                        @click="createChecklistItem(checklist.id, () => checklist.isAddingChecklistTask = false)"
                                                    />

                                                    <v-btn
                                                        icon="mdi-close"
                                                        @click="checklist.isAddingChecklistTask = false"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 class="mb-3">
                                        <v-icon
                                            icon="mdi-comment-outline"
                                            size="small"
                                        />&nbsp;Kommentare
                                    </h3>

                                    <div class="d-flex ga-3 align-start">
                                        <UserAvatar
                                            :user="user"
                                            size="small"
                                            disable-tooltip
                                        />

                                        <v-card
                                            v-if="!newComment"
                                            color="white"
                                            class="w-100 text-grey-darken-2"
                                            rounded="lg"
                                            border
                                            @click="newComment = true; nextTick(() => commentEditor.editor.commands.focus())"
                                        >
                                            Schreiben Sie einen Kommentar...
                                        </v-card>

                                        <div
                                            v-if="newComment"
                                            class="d-flex flex-column ga-2"
                                        >
                                            <VuetifyTiptap
                                                ref="commentEditor"
                                                v-model="commentForm.content"
                                                :min-height="200"
                                                :max-height="465"
                                                :rounded="false"
                                                hide-bubble
                                                output="html"
                                                :disabled="commentForm.processing"
                                                @keydown.esc.stop="newComment = false; commentForm.reset()"
                                            />

                                            <div class="d-flex ga-3">
                                                <v-btn
                                                    id="save-new-comment-button"
                                                    text="Speichern"
                                                    variant="tonal"
                                                    :disabled="commentEditor?.editor.isEmpty"
                                                    :loading="commentForm.processing"
                                                    @click="createComment"
                                                />

                                                <v-btn
                                                    text="Abbrechen"
                                                    variant="text"
                                                    :disabled="commentForm.processing"
                                                    @click="newComment = false; commentForm.reset()"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-column ga-3 mt-3">
                                        <div
                                            class="d-flex ga-3 align-start"
                                            v-for="comment in task.comments"
                                        >
                                            <UserAvatar
                                                :user="comment.user"
                                                size="small"
                                                disable-tooltip
                                            />

                                            <div class="d-flex flex-column flex-grow-1">
                                                <div class="d-flex ga-2 align-center">
                                                    <span class="font-weight-bold">{{
                                                            comment.user.firstname
                                                        }}&nbsp;{{ comment.user.lastname }}</span>
                                                    <span class="text-caption">
                                                        <timeago
                                                            class="text-caption text-grey-darken-2"
                                                            :datetime="new Date(comment.created_at)"
                                                            :locale="de"
                                                        />
                                                    </span>
                                                </div>

                                                <v-card
                                                    color="white"
                                                    class="w-100 comment-content"
                                                    rounded="lg"
                                                    border
                                                    v-html="comment.content"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-container>
                        </v-col>

                        <v-col v-if="user.tenant !== 'portal'" cols="auto">
                            <v-container class="d-flex flex-column ga-2">
                                <div class="text-end">
                                    <v-btn
                                        icon="mdi-close"
                                        variant="text"
                                        @click="requestClose"
                                    />
                                </div>

                                <span class="text-caption font-weight-bold">Zu Karte hinzufügen</span>
                                <v-dialog
                                    width="450"
                                >
                                    <template #activator="{ props }">
                                        <v-btn
                                            prepend-icon="mdi-account-outline"
                                            :text="[Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.company.id) ? 'Agenturmitglied' : 'Mitglieder'"
                                            variant="tonal"
                                            class="text-none justify-start"
                                            v-bind="props"
                                            @click="taskForm.members = task?.members?.map(member => member.id)"
                                        />
                                    </template>

                                    <template #default="{ isActive }">
                                        <v-card>
                                            <v-card-title>
                                                <span class="text-h5">
                                                    {{[Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.company.id) ? 'Agenturmitglied' : 'Mitglieder'}} bearbeiten
                                                </span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-autocomplete
                                                    v-model="taskForm.members"
                                                    :multiple="![Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.company.id)"
                                                    :chips="true"
                                                    :items="task.project?.members?.map(member => { return { title: `${member.firstname} ${member.lastname}`, value: member.id } })"
                                                    variant="outlined"
                                                    :label="[Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.company.id) ? 'Agenturmitglied' : 'Mitglieder'"
                                                />
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer/>
                                                <v-btn
                                                    text="Abbrechen"
                                                    :disabled="taskForm.processing"
                                                    @click="isActive.value = false"
                                                />
                                                <v-btn
                                                    color="primary"
                                                    text="Speichern"
                                                    :loading="taskForm.processing"
                                                    :disabled="taskForm.processing"
                                                    @click="saveTaskField('members', () => isActive.value = false)"
                                                />
                                            </v-card-actions>
                                        </v-card>
                                    </template>
                                </v-dialog>
                                <v-btn
                                    v-if="false"
                                    prepend-icon="mdi-tag-outline"
                                    text="Labels"
                                    class="text-none justify-start"
                                />
                                <v-menu
                                    v-model="isShowingNewChecklistMenu"
                                    :close-on-content-click="false"
                                    :open-on-click="false"
                                    :transition="false"
                                >
                                    <template #activator="{props}">
                                        <v-btn
                                            v-bind="props"
                                            prepend-icon="mdi-checkbox-marked-outline"
                                            text="Checkliste"
                                            variant="tonal"
                                            class="text-none justify-start"
                                            @click="isShowingNewChecklistMenu = true; $nextTick(() => newChecklistTextField.focus())"
                                        />
                                    </template>
                                    <v-list>
                                        <v-list-item>
                                            <div class="d-flex flex-column ga-3">
                                                <div class="d-flex ga-3 align-center">
                                                    <span>Neue Checkliste hinzufügen</span>
                                                    <v-spacer/>
                                                    <v-icon
                                                        icon="mdi-close"
                                                        variant="flat"
                                                        size="small"
                                                        @click="isShowingNewChecklistMenu = false; checklistForm.reset()"
                                                    />
                                                </div>

                                                <v-text-field
                                                    ref="newChecklistTextField"
                                                    v-model="checklistForm.name"
                                                    label="Checklist Name"
                                                    type="text"
                                                    variant="outlined"
                                                    :hide-details="true"
                                                    density="compact"
                                                    @keydown.enter="addNewChecklist(() => isShowingNewChecklistMenu = false)"
                                                />

                                                <div>
                                                    <v-btn
                                                        class="text-none"
                                                        text="Hinzufügen"
                                                        color="primary"
                                                        @click="addNewChecklist(() => isShowingNewChecklistMenu = false)"
                                                    />
                                                </div>
                                            </div>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <v-menu
                                    v-model="isShowingDeadlineMenu"
                                    :close-on-content-click="false"
                                    :open-on-click="false"
                                    :transition="false"
                                >
                                    <template #activator="{props}">
                                        <v-btn
                                            v-bind="props"
                                            prepend-icon="mdi-clock-outline"
                                            text="Frist"
                                            variant="tonal"
                                            class="text-none justify-start"
                                            @click="isShowingDeadlineMenu = true"
                                        />
                                    </template>
                                    <v-list>
                                        <v-list-item>
                                            <div class="d-flex flex-column ga-3">
                                                <div class="d-flex ga-3 align-center">
                                                    <span v-if="task.deadline">Frist ändern</span>
                                                    <span v-else>Neue Frist hinzufügen</span>
                                                    <v-spacer/>
                                                    <v-icon
                                                        icon="mdi-close"
                                                        variant="flat"
                                                        size="small"
                                                        @click="isShowingDeadlineMenu = false; taskForm.reset('deadline')"
                                                    />
                                                </div>

                                                <v-menu
                                                    v-model="isShowingDeadlineDateMenu"
                                                    :close-on-content-click="false"
                                                >
                                                    <template v-slot:activator="{ props }">
                                                        <v-text-field
                                                            label="Frist"
                                                            :model-value="new Date(taskForm.deadline)?.toLocaleDateString(undefined, {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric'
                                                            })"
                                                            readonly
                                                            v-bind="props"
                                                            variant="outlined"
                                                            density="compact"
                                                            hide-details
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        :model-value="new Date(taskForm.deadline)"
                                                        @update:modelValue="value => {
                                                            value.setHours(12)
                                                            taskForm.deadline = value.toISOString().slice(0, 10)
                                                            isShowingDeadlineDateMenu = false
                                                        }"
                                                        color="primary"
                                                    />
                                                </v-menu>

                                                <div class="d-flex ga-2">
                                                    <v-btn
                                                        v-if="task.deadline"
                                                        class="text-none"
                                                        text="Löschen"
                                                        color="red"
                                                        @click="taskForm.deadline = null; saveTaskField('deadline', () => isShowingDeadlineMenu = false)"
                                                    />

                                                    <v-btn
                                                        class="text-none"
                                                        :text="task.deadline ? 'Ändern' : 'Hinzufügen'"
                                                        color="primary"
                                                        @click="saveTaskField('deadline', () => isShowingDeadlineMenu = false)"
                                                    />
                                                </div>
                                            </div>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <v-btn
                                    v-if="false"
                                    prepend-icon="mdi-paperclip"
                                    text="Anhang"
                                    class="text-none justify-start"
                                />

                                <v-spacer/>

                                <span class="text-caption font-weight-bold">Aktionen</span>

                                <v-menu
                                    :close-on-content-click="false"
                                >
                                    <template #activator="{props}">
                                        <v-btn
                                            v-bind="props"
                                            prepend-icon="mdi-trash-can-outline"
                                            text="Löschen"
                                            class="text-none justify-start"
                                            color="red"
                                        />
                                    </template>
                                    <v-list>
                                        <v-list-item>
                                            <div>
                                                <span>Wirklich löschen?</span>
                                            </div>
                                            <v-btn
                                                prepend-icon="mdi-trash-can-outline"
                                                text="Ja, Löschen!"
                                                color="red"
                                                :loading="deleteTaskLoading"
                                                @click="deleteTask"
                                            />
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-container>
                        </v-col>

                        <v-col v-else cols="auto">
                            <v-container class="d-flex flex-column ga-2">
                                <div class="text-end">
                                    <v-btn
                                        icon="mdi-close"
                                        variant="text"
                                        @click="requestClose"
                                    />
                                </div>
                            </v-container>
                        </v-col>
                    </v-row>
                </template>
            </v-card>

            <v-dialog
                :model-value="hasCreatedCommentModal"
                :persistent="true"
                width="auto"
            >
                <v-card>
                    <v-card-text>
                        Hast du einen Kommentar hinterlassen?
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                            @click="hasCreatedCommentModal = false; newComment = true; nextTick(() => { commentEditor.editor.commands.focus(); window.document.getElementById('save-new-comment-button').scrollIntoView() })"
                        >
                            Nein
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="$emit('update:modelValue', false); hasCreatedCommentModal = false"
                        >
                            Ja
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </v-dialog>
</template>

<style scoped>
.description-view:deep(li) {
    margin-left: 1em;
}

.title-text-field:deep(input) {
    font-size: 1.5em;
    font-weight: bold;
}

.comment-content {
    font-size: calc(100% - 10%);
}
</style>
