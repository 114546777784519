<script setup>
import {Link, useForm, usePage} from "@inertiajs/vue3";
import UserAvatar from "@/Components/UserAvatar.vue";
import DetailsWrapper from "@/Pages/Console/Projects/DetailsWrapper.vue";
import {computed, ref} from "vue";
import NewEditProjectDialog from "@/Components/Projects/NewEditProjectDialog.vue";
import Default from "@/Layouts/Default.vue";
import {Companies} from "@/Helper/exclusion-helper.js";

defineOptions({layout: [Default, DetailsWrapper]})
const props = defineProps({
    project: Object,
    team: Array,
    customers: Array,
    integrations: Object,
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const membersForm = useForm({
    members: props.project.members.map(member => member.id)
})

const integrationLemcalForm = useForm({
    username: props.integrations?.lemcal?.username,
    password: props.integrations?.lemcal?.username ? '********************************' : undefined,
})

const isNewEditProjectDialogOpen = ref(false)

function updateMembers(isModalOpen) {
    membersForm.patch(route('projects.details.update-members', props.project.id), {
        onSuccess: () => isModalOpen.value = false
    })
}

function saveLemcalIntegration() {
    integrationLemcalForm.post(route('projects.details.integration.lemcal.connect', {project: props.project.id}))
}

function deleteLemcalIntegration() {
    integrationLemcalForm.delete(route('projects.details.integration.lemcal.disconnect', {project: props.project.id}), {
        onSuccess: () => {
            integrationLemcalForm.username = undefined
            integrationLemcalForm.password = undefined
        }
    })
}
</script>

<template>
    <v-container
        style="overflow-y: scroll; max-width: 1200px"
        class="h-100 d-flex flex-column flex-nowrap"
    >
        <v-row class="flex-grow-0">
            <v-col cols="auto">
                <v-avatar
                    icon="mdi-folder-outline"
                    size="x-large"
                    variant="tonal"
                />
            </v-col>

            <v-col>
                <div class="d-flex flex-column">
                    <v-row>
                        <v-col class="d-flex align-center">
                            <h2>
                                {{ project?.name }}
                            </h2>

                            <v-spacer/>

                            <v-btn
                                v-if="user.permissions.includes('update_projects')"
                                icon="mdi-pencil"
                                variant="tonal"
                                @click="isNewEditProjectDialogOpen = true"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            <v-label>Kunde</v-label>
                        </v-col>
                        <v-col>
                            <Link
                                :href="$route('customers.details', project?.customer?.id)"
                                class="font-weight-bold"
                                style="text-decoration: none; color: inherit"
                            >
                                {{ project?.customer?.name }}
                            </Link>
                        </v-col>
                    </v-row>
                    <v-row class="align-center">
                        <v-col cols="2">
                            <v-label>Team</v-label>
                        </v-col>
                        <v-col>
                            <div class="d-flex ga-1">
                                <UserAvatar
                                    v-for="member in project?.members"
                                    :key="member.id"
                                    :user="{...member}"
                                />

                                <v-dialog
                                    v-if="user.permissions.includes('update_projects')"
                                    width="450"
                                >
                                    <template #activator="{ props: activatorProps }">
                                        <v-btn
                                            :icon="true"
                                            variant="text"
                                            v-bind="activatorProps"
                                            @click=""
                                        >
                                            <v-avatar
                                                icon="mdi-account-edit"
                                                size="large"
                                                style="border: 1px grey dashed"
                                            />
                                            <v-tooltip
                                                activator="parent"
                                                location="bottom"
                                                text="Teammitglieder bearbeiten"
                                            />
                                        </v-btn>
                                    </template>

                                    <template #default="{ isActive }">
                                        <v-card>
                                            <v-card-title>
                                                <span class="text-h5">Teammitglieder bearbeiten</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-autocomplete
                                                    v-model="membersForm.members"
                                                    :multiple="true"
                                                    :chips="true"
                                                    :items="team.map(member => { return { title: `${member.firstname} ${member.lastname}`, value: member.id } })"
                                                    variant="outlined"
                                                    label="Mitglieder"
                                                >
                                                </v-autocomplete>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer/>
                                                <v-btn
                                                    text="Abbrechen"
                                                    :disabled="membersForm.processing"
                                                    @click="isActive.value = false"
                                                />
                                                <v-btn
                                                    color="primary"
                                                    text="Speichern"
                                                    :loading="membersForm.processing"
                                                    :disabled="membersForm.processing"
                                                    @click="updateMembers(isActive)"
                                                />
                                            </v-card-actions>
                                        </v-card>
                                    </template>
                                </v-dialog>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-if="project?.start && project?.end">
                        <v-col cols="2">
                            <v-label>Zeitraum</v-label>
                        </v-col>
                        <v-col>
                            {{
                                new Date(project?.start).toLocaleDateString(undefined, {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                })
                            }} - {{
                                new Date(project?.end).toLocaleDateString(undefined, {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                })
                            }}
                        </v-col>
                    </v-row>
                    <v-row v-if="project?.budget">
                        <v-col cols="2">
                            <v-label>Budget</v-label>
                        </v-col>
                        <v-col>
                            {{ parseFloat(project?.budget).toLocaleString() }} {{
                                project?.budget_type === 'money' ? '€' : project?.budget_type === 'time' ? 'Stunden' : ''
                            }}
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <v-row class="flex-grow-0">
            <v-col>
                <span style="white-space: pre-wrap">
                    {{ project?.description }}
                </span>
            </v-col>
        </v-row>

        <v-row
            v-if="[Companies.VALUEVNTRS, Companies.JOINY_DEV].includes(user.company.id) && user.permissions.includes('update_projects')"
            class="flex-grow-0"
        >
            <v-col>
                <v-divider/>
            </v-col>
        </v-row>

        <v-row
            v-if="[Companies.VALUEVNTRS, Companies.JOINY_DEV].includes(user.company.id) && user.permissions.includes('update_projects')"
            class="flex-grow-0"
        >
            <v-col class="d-flex flex-column ga-5">
                <h3>Integrationen</h3>

                <v-row class="align-center">
                    <v-col cols="3">
                        <img
                            src="/img/projects/lemcal.svg"
                            alt="Lemcal Logo"
                            width="150px"
                        >
                    </v-col>

                    <v-col>
                        <div class="d-flex ga-3 align-center">
                            <v-text-field
                                v-model="integrationLemcalForm.username"
                                label="Benutzername"
                                variant="outlined"
                                hide-details="auto"
                                density="compact"
                                :disabled="integrationLemcalForm.processing || props.integrations?.lemcal !== null"
                            />

                            <v-text-field
                                v-model="integrationLemcalForm.password"
                                label="Passwort"
                                type="password"
                                variant="outlined"
                                hide-details="auto"
                                density="compact"
                                :disabled="integrationLemcalForm.processing || props.integrations?.lemcal !== null"
                            />

                            <v-btn
                                v-if="!props.integrations?.lemcal"
                                text="Verbinden"
                                color="primary"
                                prepend-icon="mdi-connection"
                                :loading="integrationLemcalForm.processing"
                                @click="saveLemcalIntegration"
                            />

                            <v-btn
                                v-else
                                text="Trennen"
                                color="red"
                                prepend-icon="mdi-power-plug-off-outline"
                                :loading="integrationLemcalForm.processing"
                                @click="deleteLemcalIntegration"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="flex-grow-0">
            <v-col>
                <v-divider/>
            </v-col>
        </v-row>

        <NewEditProjectDialog
            v-model="isNewEditProjectDialogOpen"
            :project="project"
            :team="team"
            :customers="customers"
            @close="isNewEditProjectDialogOpen = false"
        />
    </v-container>
</template>
