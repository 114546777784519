<script setup>
import {nextTick, onMounted, ref} from "vue";
import Default from "@/Layouts/Default.vue";
import Wrapper from "@/Pages/Console/Settings/Wrapper.vue";
import {router, useForm} from "@inertiajs/vue3";
import {validationRules} from "@/helper.js";
import NewSubscriptionDialog from "@/Components/NewSubscriptionDialog.vue";
import {useToast} from "vue-toastification";

defineOptions({layout: [Default, Wrapper]})
const props = defineProps({
    company: Object,
    product: Object,
    subscription: Object,
    stripePublishableKey: String,
    products: Array,
    paymentMethods: Array,
    defaultPaymentMethod: Object,
})

const toast = useToast()

const companyLogoInput = ref()
const companyLogoPreviewUrl = ref(undefined)

const form = useForm({
    logo: undefined,
    name: props.company.name,
    street: props.company.street,
    street_number: props.company.street_number,
    zip: props.company.zip,
    city: props.company.city,
    tax_number: props.company.tax_number,
    email: props.company.email,
    country_code: props.company.country_code,
})

const isNewSubscriptionDialogOpen = ref(false)
const redirectToCustomerPortalLoading = ref(false)

const productsLoading = ref(false)

onMounted(() => {
    if (route().params.redirect_status === 'failed') {
        nextTick(() => toast.error(`Es ist ein Problem bei der Zahlung aufgetreten. Bitte versuche es erneut.`))
    }
})

function redirectToCustomerPortal() {
    redirectToCustomerPortalLoading.value = true
    window.location.href = route('settings.billing-portal')
}

function newCompanyLogoSelected(event) {
    form.logo = event.target.files[0]

    const fileReader = new FileReader()
    fileReader.onload = function (e) {
        companyLogoPreviewUrl.value = e.target.result
    }
    fileReader.readAsDataURL(form.logo)
}

function saveCompanyData() {
    form.put(route('settings.company.update'))
}

function startCreatingNewSubscription() {
    productsLoading.value = true
    router.reload({
        only: ['products', 'paymentMethods', 'defaultPaymentMethod'],
        onSuccess: () => {
            isNewSubscriptionDialogOpen.value = true
        },
        onFinish: () => productsLoading.value = false
    })
}
</script>

<template>
    <v-row class="flex-grow-0">
        <v-col>
            <v-row>
                <v-col>
                    <v-card class="pa-5 d-flex flex-column ga-3">
                        <div>
                            <h3>Firmen-Logo</h3>

                            <span class="text-caption font-weight-light">Dein Logo, sichtbar für deine Kunden und Mitarbeiter</span>
                        </div>

                        <div
                            @click="companyLogoInput.click()"
                        >
                            <v-img
                                v-if="companyLogoPreviewUrl || company.logo_url"
                                height="80"
                                id="company-logo"
                                rounded="0"
                                class="cursor-pointer"
                                :src="companyLogoPreviewUrl || company.logo_url"
                                icon="mdi-pen"
                                size="100%"
                            />
                            <div
                                v-else
                                class="d-flex flex-column h-100 justify-center align-center"
                                style="pointer-events: none"
                            >
                                <v-icon
                                    icon="mdi-cloud-upload-outline"
                                    size="50"
                                />
                                <span>Logo hochladen</span>
                            </div>
                            <input
                                ref="companyLogoInput"
                                hidden="hidden"
                                type="file"
                                accept="image/jpeg,image/png"
                                @change="newCompanyLogoSelected"
                            >
                        </div>

                        <div class="text-caption text-center mt-3 font-weight-light">
                            <span>JPG und PNG, max. Größe: 5MB</span>
                        </div>
                    </v-card>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-card class="pa-5 d-flex flex-column ga-3">
                        <div>
                            <h3>Abonnement</h3>
                        </div>

                        <v-card
                            class="d-flex flex-column pa-4 ga-3 flex-grow-1 mt-3"
                            style="border-radius: 25px"
                        >
                            <div
                                class="d-flex flex-column"
                                :class="{ 'text-grey': !product.active }"
                            >
                                <span
                                    class="text-h3 font-weight-black"
                                >{{ product.name }}</span>

                                <span
                                    v-if="product.active"
                                    class="font-weight-bold"
                                >
                                            Aktueller Plan
                                            <span v-if="subscription.ends_at" class="text-caption">
                                                <br>
                                                gekündigt zum {{
                                                    new Date(subscription.ends_at).toLocaleDateString(undefined, {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric'
                                                    })
                                                }}</span>
                                        </span>

                                <span v-else class="font-weight-bold">Inaktiver Plan</span>
                            </div>

                            <v-divider/>

                            <v-btn
                                v-if="!product.active"
                                variant="flat"
                                color="primary"
                                size="large"
                                :loading="productsLoading"
                                @click="startCreatingNewSubscription()"
                            >
                                Neues Abo abschließen
                            </v-btn>

                            <v-btn
                                variant="outlined"
                                size="large"
                                :loading="redirectToCustomerPortalLoading"
                                @click="redirectToCustomerPortal"
                            >
                                Abo und Rechnungen
                            </v-btn>
                        </v-card>

                    </v-card>
                </v-col>
            </v-row>
        </v-col>

        <v-col>
            <v-card class="pa-5 d-flex flex-column ga-3" :disabled="form.processing">
                <div>
                    <h3>Firmen-Adresse</h3>

                    <span class="text-caption font-weight-light">
                        ändere deine Firmen-Adresse hier
                    </span>
                </div>

                <v-row>
                    <v-col
                        cols="7"
                        class="pb-0"
                    >
                        <v-text-field
                            v-model="form.name"
                            label="Firmenname"
                            type="text"
                            variant="outlined"
                            :rules="[validationRules.required]"
                        />
                    </v-col>

                    <v-col
                        cols="5"
                        class="pb-0"
                    >
                        <v-text-field
                            v-model="form.tax_number"
                            label="Umsatzsteuer-IdNr."
                            type="text"
                            variant="outlined"
                            :rules="[validationRules.taxId]"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col
                        cols="7"
                        class="pb-0"
                    >
                        <v-text-field
                            v-model="form.street"
                            label="Straße / Postfach"
                            type="text"
                            variant="outlined"
                            :rules="[validationRules.required]"
                        />
                    </v-col>

                    <v-col
                        cols="5"
                        class="pb-0"
                    >
                        <v-text-field
                            v-model="form.street_number"
                            label="Haus-/Postfach-Nummer"
                            type="text"
                            variant="outlined"
                            :rules="[validationRules.required]"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col
                        cols="3"
                        class="py-0"
                    >
                        <v-text-field
                            v-model="form.zip"
                            label="PLZ"
                            type="text"
                            variant="outlined"
                            :rules="[validationRules.required]"
                        />
                    </v-col>

                    <v-col
                        cols="9"
                        class="py-0"
                    >
                        <v-text-field
                            v-model="form.city"
                            label="Ort"
                            type="text"
                            variant="outlined"
                            :rules="[validationRules.required]"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col
                        class="py-0"
                    >
                        <v-select
                            v-model="form.country_code"
                            :items="[{ title: 'Deutschland', value: 'DE' }, { title: 'Österreich', value: 'AT' }, { title: 'Schweiz', value: 'CH' }]"
                            :disabled="form.processing"
                            label="Land"
                            variant="outlined"
                            :rules="[validationRules.required]"
                        />
                    </v-col>

                    <v-col
                        class="py-0"
                    >
                        <v-text-field
                            v-model="form.email"
                            :disabled="form.processing"
                            label="E-Mail-Adresse"
                            variant="outlined"
                            :rules="[validationRules.required, validationRules.email]"
                        />
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>

    <v-row class="flex-grow-0">
        <v-col class="text-end">
            <v-btn
                prepend-icon="mdi-content-save-outline"
                :loading="form.processing"
                :disabled="!form.isDirty"
                text="Speichern"
                color="primary"
                type="submit"
                @click="saveCompanyData"
            />
        </v-col>
    </v-row>

    <!-- New Subscription Dialog -->
    <NewSubscriptionDialog
        v-if="products"
        :is-open="isNewSubscriptionDialogOpen"
        :products="products"
        :company="company"
        :payment-methods="paymentMethods"
        :default-payment-method="defaultPaymentMethod"
        :stripe-publishable-key="stripePublishableKey"
        @close="isNewSubscriptionDialogOpen = false"
    />
</template>

<style scoped>

</style>
