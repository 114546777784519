<script setup>
import {computed, nextTick, ref, watch} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import UserAvatar from "@/Components/UserAvatar.vue";
import {de} from "date-fns/locale";

const props = defineProps({
    modelValue: Boolean,
    onboarding: Object,
    isTemplate: Boolean
})

const emit = defineEmits([
    'update:modelValue'
])

const page = usePage()
const user = computed(() => page.props.auth.user)

const taskForm = useForm({
    description: undefined,
    deadline: undefined,
})

const commentForm = useForm({
    content: undefined
})

const checklistForm = useForm({
    name: undefined
})

const checklistItemForm = useForm({
    name: undefined
})

const isShowingDeadlineMenu = ref(false)
watch(isShowingDeadlineMenu, (newIsShowingDeadlineMenu) => {
    if (newIsShowingDeadlineMenu) {
        taskForm.deadline = props.onboarding.task.deadline || getCurrentDeadlineDate()
    } else {
        taskForm.reset('deadline')
    }
})
const isShowingDeadlineDateMenu = ref(false)

const isShowingNewChecklistMenu = ref(false)
const newChecklistTextField = ref()

const isChangingDescription = ref(false)
const descriptionEditor = ref()

const newChecklistItemTextField = ref()

const newComment = ref(false)
const commentEditor = ref()

function getCurrentDeadlineDate() {
    let deadlineDate = new Date()
    deadlineDate.setHours(12)
    deadlineDate.toISOString().slice(0, 10)
    return deadlineDate
}

function getChecklistProgress(checklist) {
    return Math.ceil(checklist.items?.filter(t => t.done === true).length / checklist.items?.length * 100)
}

function saveTaskField(fieldName, onSuccess) {
    taskForm.patch(
        route('onboardings.task-update', {onboarding: props.onboarding.id}),
        {
            onSuccess: () => {
                taskForm.reset(fieldName)
                router.reload()
                onSuccess()
            }
        }
    )
}

function addNewChecklist(onSuccess) {
    checklistForm.post(
        route('onboardings.checklist-create', {onboarding: props.onboarding.id}),
        {
            onSuccess: () => {
                checklistForm.reset()
                router.reload()
                onSuccess()
            }
        }
    )
}

function createChecklistItem(checklistId, onSuccess) {
    checklistItemForm.post(
        route('onboardings.checklist-item-create', {
            onboarding: props.onboarding.id,
            checklist: checklistId
        }),
        {
            onSuccess: () => {
                checklistItemForm.reset()
                router.reload()
                onSuccess()
            }
        }
    )
}

function setChecklistItemDone(checklistId, checklistItemId, isDone) {
    router.patch(
        route('onboardings.checklist-item-update', {
            onboarding: props.onboarding.id,
            checklist: checklistId,
            checklistItem: checklistItemId
        }),
        {
            done: isDone
        },
        {
            onSuccess: () => router.reload()
        }
    )
}

function deleteChecklistItem(checklistId, checklistItemId) {
    router.delete(
        route('onboardings.checklist-item-delete', {
            onboarding: props.onboarding.id,
            checklist: checklistId,
            checklistItem: checklistItemId
        }),
        {
            onSuccess: () => router.reload()
        }
    )
}

function deleteChecklist(checklistId) {
    router.delete(
        route('onboardings.checklist-delete', {
            onboarding: props.onboarding.id,
            checklist: checklistId,
        }),
        {
            onSuccess: () => router.reload()
        }
    )
}

function createComment() {
    commentForm.post(route('onboardings.comment-create', {onboarding: props.onboarding.id}), {
        preserveScroll: true,
        onSuccess: () => {
            newComment.value = false
            commentForm.reset()
        }
    })
}

function startChangingDescription() {
    isChangingDescription.value = true
    taskForm.description = props.onboarding.task.description
    nextTick(() => descriptionEditor.value.editor.commands.focus('end'))
}
</script>

<template>
    <v-dialog
        :model-value="modelValue"
        @update:modelValue="newValue => $emit('update:modelValue', newValue)"
        max-width="1000px"
    >
        <template #default>
            <v-card v-if="onboarding.task">
                <template #default>
                    <v-row>
                        <v-col>
                            <v-container class="d-flex flex-column ga-3">
                                <div>
                                    <h2>{{ onboarding.task.name }}</h2>
                                    <span v-if="onboarding.customer" class="text-grey-darken-1">für <b>{{ onboarding.customer.name }}</b></span>
                                </div>

                                <div class="d-flex ga-3">
                                    <div
                                        v-if="onboarding.task.deadline"
                                        class="d-flex flex-column ga-2"
                                    >
                      <span class="text-body-2 text-grey-darken-1 font-weight-bold">
                        Frist
                      </span>
                                        <span class="text-subtitle-2 flex-grow-1 align-center d-flex font-weight-bold">
                        {{
                                                new Date(onboarding.task.deadline).toLocaleString(undefined, {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric'
                                                })
                                            }}
                      </span>
                                    </div>
                                </div>

                                <div>
                                    <h3 class="mb-3">
                                        <v-icon
                                            icon="mdi-text"
                                            size="small"
                                        />&nbsp;Beschreibung
                                    </h3>

                                    <div
                                        v-if="!isChangingDescription && onboarding.task.description"
                                        :class="{ 'cursor-pointer': user.tenant !== 'portal' }"
                                        style="white-space: pre-wrap"
                                        @click="startChangingDescription"
                                    >
                                      <span class="description-view" v-html="onboarding.task.description" />
                                    </div>

                                    <v-btn
                                        v-if="!isChangingDescription && !onboarding.task.description"
                                        class="w-100 justify-start align-start pt-5 text-none"
                                        style="min-height: 70px"
                                        text="Detaillierte Beschreibung hinzufügen ..."
                                        @click="isChangingDescription = true; $nextTick(() => descriptionEditor.editor.commands.focus())"
                                    />

                                    <div
                                        v-if="isChangingDescription"
                                        class="d-flex flex-column ga-3"
                                    >
                                        <VuetifyTiptap
                                            ref="descriptionEditor"
                                            v-model="taskForm.description"
                                            :min-height="200"
                                            :max-height="465"
                                            :rounded="false"
                                            hide-bubble
                                            output="html"
                                            :disabled="taskForm.processing"
                                            @keydown.esc.stop="isChangingDescription = false; taskForm.reset('description')"
                                        />

                                        <div class="d-flex ga-3">
                                            <v-btn
                                                text="Speichern"
                                                variant="tonal"
                                                :disabled="descriptionEditor?.editor.isEmpty"
                                                :loading="taskForm.processing"
                                                @click="saveTaskField('description', () => isChangingDescription = false)"
                                            />

                                            <v-btn
                                                text="Abbrechen"
                                                variant="text"
                                                :disabled="taskForm.processing"
                                                @click="isChangingDescription = false; taskForm.reset('description')"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-for="checklist in onboarding.task.checklists"
                                    :key="checklist.id"
                                    class="d-flex flex-column ga-1"
                                >
                                    <div class="d-flex">
                                        <h3 class="mb-3 align-center">
                                            <v-icon
                                                icon="mdi-checkbox-marked-outline"
                                                size="small"
                                            />&nbsp;{{ checklist.name }}
                                        </h3>

                                        <v-spacer/>

                                        <v-menu>
                                            <template #activator="{props}">
                                                <v-btn
                                                    v-if="user.tenant !== 'portal'"
                                                    v-bind="props"
                                                    text="Löschen"
                                                    class="text-none justify-start"
                                                />
                                            </template>
                                            <v-list>
                                                <v-list-item>
                                                    <div>
                                                        <h4>Checkliste wirklich löschen?</h4>
                                                        <p>Dieser Vorgang kann nicht rückgängig gemacht werden!</p>
                                                    </div>
                                                    <v-btn
                                                        prepend-icon="mdi-trash-can-outline"
                                                        text="Ja, löschen!"
                                                        color="red"
                                                        @click="deleteChecklist(checklist.id)"
                                                    />
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>

                                    <div
                                        v-if="checklist.items?.length"
                                        class="d-flex align-center"
                                    >
                                      <span
                                          class="text-caption"
                                      >
                                        {{ getChecklistProgress(checklist) }}&nbsp;%
                                      </span>
                                        <v-progress-linear
                                            :rounded="true"
                                            :model-value="getChecklistProgress(checklist)"
                                            color="primary"
                                        />
                                    </div>

                                    <div
                                        class="d-flex flex-column ga-3"
                                    >
                                        <div>
                                            <div
                                                v-for="item in checklist.items"
                                                :key="item.id"
                                                class="d-flex align-center ga-2"
                                            >
                                                <v-checkbox
                                                    :model-value="item.done"
                                                    class="flex-grow-0 flex-shrink-0"
                                                    :hide-details="true"
                                                    color="primary"
                                                    density="compact"
                                                    @update:modelValue="isDone => setChecklistItemDone(checklist.id, item.id, isDone)"
                                                />

                                                <span>{{ item.name }}</span>

                                                <v-spacer/>

                                                <v-btn
                                                    v-if="user.tenant !== 'portal'"
                                                    icon="mdi-trash-can-outline"
                                                    variant="text"
                                                    size="small"
                                                    color="grey-darken-1"
                                                    @click="deleteChecklistItem(checklist.id, item.id)"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <v-btn
                                                v-if="user.tenant !== 'portal'"
                                                v-show="!checklist.isAddingChecklistTask"
                                                class="text-none"
                                                text="Element hinzufügen"
                                                @click="checklist.isAddingChecklistTask = true; $nextTick(() => newChecklistItemTextField[0].focus())"
                                            />

                                            <div
                                                v-show="checklist.isAddingChecklistTask"
                                            >
                                                <v-text-field
                                                    ref="newChecklistItemTextField"
                                                    v-model="checklistItemForm.name"
                                                    variant="outlined"
                                                    placeholder="Element hinzufügen"
                                                    density="compact"
                                                    :hide-details="true"
                                                    maxlength="255"
                                                    @keydown.enter="createChecklistItem(checklist.id, () => checklist.isAddingChecklistTask = false)"
                                                />

                                                <div class="d-flex align-center ga-1">
                                                    <v-btn
                                                        class="text-none"
                                                        text="Speichern"
                                                        color="primary"
                                                        @click="createChecklistItem(checklist.id, () => checklist.isAddingChecklistTask = false)"
                                                    />

                                                    <v-btn
                                                        icon="mdi-close"
                                                        @click="checklist.isAddingChecklistTask = false"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="!isTemplate">
                                    <h3 class="mb-3">
                                        <v-icon
                                            icon="mdi-comment-outline"
                                            size="small"
                                        />&nbsp;Kommentare
                                    </h3>

                                    <div class="d-flex ga-3 align-start">
                                        <UserAvatar
                                            :user="user"
                                            size="small"
                                            disable-tooltip
                                        />

                                        <v-card
                                            v-if="!newComment"
                                            color="white"
                                            class="w-100 text-grey-darken-2"
                                            rounded="lg"
                                            border
                                            @click="newComment = true; nextTick(() => commentEditor.editor.commands.focus())"
                                        >
                                            Schreiben Sie einen Kommentar...
                                        </v-card>

                                        <div
                                            v-if="newComment"
                                            class="d-flex flex-column ga-2"
                                        >
                                            <VuetifyTiptap
                                                ref="commentEditor"
                                                v-model="commentForm.content"
                                                :min-height="200"
                                                :max-height="465"
                                                :rounded="false"
                                                hide-bubble
                                                output="html"
                                                :disabled="commentForm.processing"
                                                @keydown.esc.stop="newComment = false; commentForm.reset()"
                                            />

                                            <div class="d-flex ga-3">
                                                <v-btn
                                                    text="Speichern"
                                                    variant="tonal"
                                                    :disabled="commentEditor?.editor.isEmpty"
                                                    :loading="commentForm.processing"
                                                    @click="createComment"
                                                />

                                                <v-btn
                                                    text="Abbrechen"
                                                    variant="text"
                                                    :disabled="commentForm.processing"
                                                    @click="newComment = false; commentForm.reset()"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-column ga-3 mt-3">
                                        <div
                                            class="d-flex ga-3 align-start"
                                            v-for="comment in onboarding.task.comments"
                                        >
                                            <UserAvatar
                                                :user="comment.user"
                                                size="small"
                                                disable-tooltip
                                            />

                                            <div class="d-flex flex-column flex-grow-1">
                                                <div class="d-flex ga-2 align-center">
                                                    <span class="font-weight-bold">{{
                                                            comment.user.firstname
                                                        }}&nbsp;{{ comment.user.lastname }}</span>
                                                    <span class="text-caption">
                                                        <timeago
                                                            class="text-caption text-grey-darken-2"
                                                            :datetime="new Date(comment.created_at)"
                                                            :locale="de"
                                                        />
                                                    </span>
                                                </div>

                                                <v-card
                                                    color="white"
                                                    class="w-100 comment-content"
                                                    rounded="lg"
                                                    border
                                                    v-html="comment.content"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-container>
                        </v-col>

                        <v-col v-if="user.tenant !== 'portal'" cols="auto">
                            <v-container class="d-flex flex-column ga-2">
                                <div class="text-end">
                                    <v-btn
                                        icon="mdi-close"
                                        variant="text"
                                        @click="$emit('update:modelValue', false)"
                                    />
                                </div>

                                <span class="text-caption font-weight-bold">Zu Karte hinzufügen</span>

                                <v-btn
                                    v-if="false"
                                    prepend-icon="mdi-tag-outline"
                                    text="Labels"
                                    class="text-none justify-start"
                                />
                                <v-menu
                                    v-model="isShowingNewChecklistMenu"
                                    :close-on-content-click="false"
                                    :open-on-click="false"
                                    :transition="false"
                                >
                                    <template #activator="{props}">
                                        <v-btn
                                            v-bind="props"
                                            prepend-icon="mdi-checkbox-marked-outline"
                                            text="Checkliste"
                                            variant="tonal"
                                            class="text-none justify-start"
                                            @click="isShowingNewChecklistMenu = true; $nextTick(() => newChecklistTextField.focus())"
                                        />
                                    </template>
                                    <v-list>
                                        <v-list-item>
                                            <div class="d-flex flex-column ga-3">
                                                <div class="d-flex ga-3 align-center">
                                                    <span>Neue Checkliste hinzufügen</span>
                                                    <v-spacer/>
                                                    <v-icon
                                                        icon="mdi-close"
                                                        variant="flat"
                                                        size="small"
                                                        @click="isShowingNewChecklistMenu = false; checklistForm.reset()"
                                                    />
                                                </div>

                                                <v-text-field
                                                    ref="newChecklistTextField"
                                                    v-model="checklistForm.name"
                                                    label="Checklist Name"
                                                    type="text"
                                                    variant="outlined"
                                                    :hide-details="true"
                                                    density="compact"
                                                    @keydown.enter="addNewChecklist(() => isShowingNewChecklistMenu = false)"
                                                />

                                                <div>
                                                    <v-btn
                                                        class="text-none"
                                                        text="Hinzufügen"
                                                        color="primary"
                                                        @click="addNewChecklist(() => isShowingNewChecklistMenu = false)"
                                                    />
                                                </div>
                                            </div>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <v-menu
                                    v-if="false"
                                    v-model="isShowingDeadlineMenu"
                                    :close-on-content-click="false"
                                    :open-on-click="false"
                                    :transition="false"
                                >
                                    <template #activator="{props}">
                                        <v-btn
                                            v-bind="props"
                                            prepend-icon="mdi-clock-outline"
                                            text="Frist"
                                            variant="tonal"
                                            class="text-none justify-start"
                                            @click="isShowingDeadlineMenu = true"
                                        />
                                    </template>
                                    <v-list>
                                        <v-list-item>
                                            <div class="d-flex flex-column ga-3">
                                                <div class="d-flex ga-3 align-center">
                                                    <span v-if="onboarding.task.deadline">Frist ändern</span>
                                                    <span v-else>Neue Frist hinzufügen</span>
                                                    <v-spacer/>
                                                    <v-icon
                                                        icon="mdi-close"
                                                        variant="flat"
                                                        size="small"
                                                        @click="isShowingDeadlineMenu = false; taskForm.reset('deadline')"
                                                    />
                                                </div>

                                                <v-menu
                                                    v-model="isShowingDeadlineDateMenu"
                                                    :close-on-content-click="false"
                                                >
                                                    <template v-slot:activator="{ props }">
                                                        <v-text-field
                                                            label="Frist"
                                                            :model-value="new Date(taskForm.deadline)?.toLocaleDateString(undefined, {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric'
                                                            })"
                                                            readonly
                                                            v-bind="props"
                                                            variant="outlined"
                                                            density="compact"
                                                            hide-details
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        :model-value="new Date(taskForm.deadline)"
                                                        @update:modelValue="value => {
                                                            value.setHours(12)
                                                            taskForm.deadline = value.toISOString().slice(0, 10)
                                                            isShowingDeadlineDateMenu = false
                                                        }"
                                                        color="primary"
                                                    />
                                                </v-menu>

                                                <div class="d-flex ga-2">
                                                    <v-btn
                                                        v-if="onboarding.task.deadline"
                                                        class="text-none"
                                                        text="Löschen"
                                                        color="red"
                                                        @click="taskForm.deadline = null; saveTaskField('deadline', () => isShowingDeadlineMenu = false)"
                                                    />

                                                    <v-btn
                                                        class="text-none"
                                                        :text="onboarding.task.deadline ? 'Ändern' : 'Hinzufügen'"
                                                        color="primary"
                                                        @click="saveTaskField('deadline', () => isShowingDeadlineMenu = false)"
                                                    />
                                                </div>
                                            </div>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-container>
                        </v-col>

                        <v-col v-else cols="auto">
                            <v-container class="d-flex flex-column ga-2">
                                <div class="text-end">
                                    <v-btn
                                        icon="mdi-close"
                                        variant="text"
                                        @click="$emit('update:modelValue', false)"
                                    />
                                </div>
                            </v-container>
                        </v-col>
                    </v-row>
                </template>
            </v-card>
        </template>
    </v-dialog>
</template>

<style scoped>
.description-view:deep(li) {
    margin-left: 1em;
}

.comment-content {
    font-size: calc(100% - 10%);
}
</style>
