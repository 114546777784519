<script setup>
import Default from "@/Layouts/Default.vue";
import {inject} from "vue";
import {Head, useForm} from "@inertiajs/vue3";

defineOptions({layout: Default})
const props = defineProps({settings: Object})

const form = useForm({
    number_range_customer_format: props.settings.number_range_customer_format,
    number_range_customer_counter: props.settings.number_range_customer_counter
})

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {
    title: 'Einstellungen',
    back: route('customers')
})

function submit() {
    form.put(route('customers.settings.update'))
}
</script>

<template>
    <Head title="Einstellungen - Kunden"/>

    <v-container
        :fluid="true"
        style="max-width: 1200px"
    >
        <v-row>
            <v-col class="text-end">
                <v-btn
                    class="text-none"
                    prepend-icon="mdi-floppy"
                    text="Speichern"
                    color="primary"
                    @click="submit"
                    :loading="form.processing"
                    :disabled="form.processing"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-row>
                    <v-col>
                        <h3>Nummernkreise</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-if="form.number_range_customer_format"
                            v-model="form.number_range_customer_format"
                            label="Kundennummernkreis"
                            variant="outlined"
                            hint="Tag:&nbsp;{day} / Monat:&nbsp;{month} / Jahr:&nbsp;{year} / Nummer:&nbsp;{counter}"
                            :persistent-hint="true"
                        >
                            <template #append-inner>
                                <v-label>
                                    {{
                                        form.number_range_customer_format
                                            .replace('{day}', ('0' + new Date().getDate()).slice(-2))
                                            .replace('{month}', ('0' + (new Date().getMonth() + 1)).slice(-2))
                                            .replace('{year}', new Date().getFullYear())
                                            .replace('{counter}', form.number_range_customer_counter)
                                    }}
                                </v-label>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="auto"
                        style="width: 140px"
                    >
                        <v-text-field
                            v-if="form.number_range_customer_counter"
                            v-model="form.number_range_customer_counter"
                            type="number"
                            label="Kundenzähler"
                            variant="outlined"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
