<script setup>
import {Head, useForm} from '@inertiajs/vue3';
import Default from "@/Layouts/Default.vue";
import {inject, ref} from "vue";
import {validationRules} from "@/helper.js";
import {getTypeName} from "@/Helper/payments-helper.js";

defineOptions({layout: Default})
const props = defineProps({
    settings: Object,
})

const form = useForm({
    number_range_offer_format: props.settings.number_range_offer_format,
    number_range_offer_counter: props.settings.number_range_offer_counter,
    number_range_invoice_format: props.settings.number_range_invoice_format,
    number_range_invoice_counter: props.settings.number_range_invoice_counter,
    contact_phone_number: props.settings.contact_phone_number,
    contact_email: props.settings.contact_email,
    contact_website: props.settings.contact_website,
    bank_account_bank_name: props.settings.bank_account_bank_name,
    bank_account_iban: props.settings.bank_account_iban,
    bank_account_bic: props.settings.bank_account_bic,
})

const settingsForm = ref()
const isSettingsFormValid = ref(false)

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {
    title: 'Einstellungen',
    back: route('payments')
})

async function saveSettings() {
    await settingsForm.value.validate()
    if (!isSettingsFormValid.value) return

    form.put(route('payments.settings.update'), {
        preserveState: false
    })
}
</script>

<template>
    <Head title="Einstellungen - Zahlung"/>

    <v-container
        :fluid="true"
        style="max-width: 1200px"
    >
        <v-row>
            <v-col class="text-end">
                <v-btn
                    class="text-none"
                    prepend-icon="mdi-floppy"
                    text="Speichern"
                    color="primary"
                    :loading="form.processing"
                    @click="saveSettings"
                />
            </v-col>
        </v-row>

        <v-form
            v-model="isSettingsFormValid"
            ref="settingsForm"
            validate-on="none"
        >
            <v-row>
                <v-col>
                    <v-row>
                        <v-col>
                            <h3>Nummernkreise</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="form.number_range_offer_format"
                                label="Angebotsnummernkreis"
                                variant="outlined"
                                hint="Tag:&nbsp;{day} / Monat:&nbsp;{month} / Jahr:&nbsp;{year} / Nummer:&nbsp;{counter}"
                                :persistent-hint="true"
                                :rules="[validationRules.required]"
                            >
                                <template #append-inner>
                                    <v-label>
                                        {{
                                            form.number_range_offer_format
                                                .replace('{day}', ('0' + new Date().getDate()).slice(-2))
                                                .replace('{month}', ('0' + (new Date().getMonth() + 1)).slice(-2))
                                                .replace('{year}', new Date().getFullYear())
                                                .replace('{counter}', form.number_range_offer_counter)
                                        }}
                                    </v-label>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="auto"
                            style="width: 140px"
                        >
                            <v-text-field
                                v-model="form.number_range_offer_counter"
                                type="number"
                                label="Angebotszähler"
                                variant="outlined"
                                :rules="[validationRules.required]"
                            />
                        </v-col>

                        <v-col>
                            <v-text-field
                                v-model="form.number_range_invoice_format"
                                label="Rechnungsnummernkreis"
                                variant="outlined"
                                hint="Tag:&nbsp;{day} / Monat:&nbsp;{month} / Jahr:&nbsp;{year} / Nummer:&nbsp;{counter}"
                                :persistent-hint="true"
                                :rules="[validationRules.required]"
                            >
                                <template #append-inner>
                                    <v-label>
                                        {{
                                            form.number_range_invoice_format
                                                .replace('{day}', ('0' + new Date().getDate()).slice(-2))
                                                .replace('{month}', ('0' + (new Date().getMonth() + 1)).slice(-2))
                                                .replace('{year}', new Date().getFullYear())
                                                .replace('{counter}', form.number_range_invoice_counter)
                                        }}
                                    </v-label>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="auto"
                            style="width: 150px"
                        >
                            <v-text-field
                                v-model="form.number_range_invoice_counter"
                                label="Rechnungszähler"
                                variant="outlined"
                                :rules="[validationRules.required]"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <h3>Fußzeile</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="form.contact_phone_number"
                                type="tel"
                                label="Telefonnummer"
                                variant="outlined"
                                :rules="[validationRules.required]"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="form.contact_email"
                                type="email"
                                label="E-Mail Adresse"
                                variant="outlined"
                                :rules="[validationRules.required]"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="form.contact_website"
                                type="url"
                                label="Webseite"
                                variant="outlined"
                                :rules="[validationRules.required]"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="form.bank_account_bank_name"
                                label="Bank"
                                variant="outlined"
                                :rules="[validationRules.required]"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="form.bank_account_iban"
                                label="IBAN"
                                variant="outlined"
                                :rules="[validationRules.required]"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="form.bank_account_bic"
                                label="BIC"
                                variant="outlined"
                                :rules="[validationRules.required]"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
