<script setup>
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import {Head, Link, router, useForm, usePage} from '@inertiajs/vue3';
import {validationRules} from "@/helper.js";
import {VBtn, VCard, VCardText, VForm, VImg, VTextField} from "vuetify/components";
import {computed, ref} from "vue";

const page = usePage()
const branding = computed(() => page.props.branding)

const registerForm = ref()

const form = useForm({
    company: undefined,
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    password: undefined,
    referral: route().params.referral,
    promotion_code: route().params.promotion_code?.toUpperCase(),
    terms: false
});
const isFormValid = ref(false)

const submit = async () => {
    form.clearErrors()
    await registerForm.value.validate()
    if (!isFormValid.value) return

    form.post(route('register'), {
        onSuccess: () => window.gtag('event', 'sign_up'),
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <GuestLayout>
        <Head title="Register" />

        <div class="d-flex flex-column flex-grow-1">
            <div class="d-flex flex-column flex-grow-1 align-center justify-center">
                <v-card
                    class="pa-12 pb-8 flex-shrink-1"
                    width="448"
                    rounded="xl"
                >
                    <v-img
                        class="mx-auto mb-8"
                        width="228"
                        :src="branding.logo_url ?? '/img/logo.webp'"
                    />

                    <v-form
                        ref="registerForm"
                        v-model="isFormValid"
                        validate-on="submit"
                        @submit.prevent="submit"
                    >
                        <v-text-field
                            id="company"
                            type="text"
                            v-model="form.company"
                            :rules="[validationRules.required]"
                            autofocus
                            autocomplete="organization"
                            density="compact"
                            label="Firma"
                            prepend-inner-icon="mdi-domain"
                            variant="outlined"
                            :clearable="true"
                            tabindex="1"
                        />

                        <div class="d-flex ga-2">
                            <v-text-field
                                id="firstname"
                                type="text"
                                v-model="form.firstname"
                                :rules="[validationRules.required]"
                                autocomplete="given-name"
                                density="compact"
                                label="Vorname"
                                prepend-inner-icon="mdi-account-multiple-outline"
                                variant="outlined"
                                :clearable="true"
                                tabindex="2"
                            />

                            <v-text-field
                                id="lastname"
                                type="text"
                                v-model="form.lastname"
                                :rules="[validationRules.required]"
                                autocomplete="family-name"
                                density="compact"
                                label="Nachname"
                                prepend-inner-icon="mdi-account-multiple"
                                variant="outlined"
                                :clearable="true"
                                tabindex="3"
                            />
                        </div>

                        <v-text-field
                            id="email"
                            type="email"
                            v-model="form.email"
                            :rules="[validationRules.required, validationRules.email]"
                            autocomplete="username"
                            density="compact"
                            label="E-Mail-Adresse"
                            prepend-inner-icon="mdi-email-outline"
                            variant="outlined"
                            :clearable="true"
                            tabindex="4"
                        />

                        <v-text-field
                            id="password"
                            type="password"
                            v-model="form.password"
                            :rules="[validationRules.required]"
                            autocomplete="new-password"
                            density="compact"
                            label="Passwort"
                            prepend-inner-icon="mdi-lock-outline"
                            variant="outlined"
                            :clearable="true"
                            tabindex="5"
                        />

                        <v-text-field
                            v-model="form.promotion_code"
                            class="promotion-code"
                            density="compact"
                            label="Promotion-Code"
                            prepend-inner-icon="mdi-ticket-percent-outline"
                            variant="outlined"
                            :clearable="true"
                            tabindex="6"
                            @keyup="form.promotion_code = form.promotion_code.toUpperCase()"
                        />

                        <v-checkbox
                            v-model="form.terms"
                            :rules="[validationRules.required]"
                        >
                            <template #label>
                                <span>Mit dem Klick auf "Registrieren" akzeptierst du die aktuell geltenden <b><a href="https://joiny.io/terms.html" target="_blank">AGB</a></b> und <b><a href="https://joiny.io/privacy.html" target="_blank">Datenschutzerklärung</a></b>.</span>
                            </template>
                        </v-checkbox>

                        <v-alert
                            v-if="Object.keys(form.errors).length"
                            class="my-5"
                            type="error"
                            :text="Object.values(form.errors).join('\n')"
                        />

                        <v-btn
                            block
                            class="text-none"
                            size="x-large"
                            color="primary"
                            type="submit"
                            :disabled="form.processing"
                            :loading="form.processing"
                            tabindex="6"
                        >
                            Registrieren
                        </v-btn>

                    </v-form>

                    <v-card-text class="text-center d-flex flex-column ga-3">
                        <Link
                            class="text-decoration-none"
                            rel="noopener noreferrer"
                            href="/login"
                        >
                            Schon einen Account?
                        </Link>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </GuestLayout>
</template>

<style scoped>
.promotion-code:deep(input) {
    text-transform: uppercase;
}
</style>
