<script setup>
import {Head, router} from "@inertiajs/vue3";
import {inject} from "vue";

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {title: 'Chat'})
</script>

<template>
    <Head :title="`Chat`"/>

    <v-container
        :fluid="true"
        class="d-flex flex-column ga-3 h-100"
    >
        <div class="d-flex align-center">
            <v-tabs
                :model-value="$route().current()"
                centered
                color="grey-darken-2"
            >
                <v-tab
                    class="text-none"
                    text="Posteingang"
                    prepend-icon="mdi-mailbox-up-outline"
                    value="chat.message-groups.show"
                    @click="router.visit(route('chat'))"
                />

<!--                <v-tab-->
<!--                    class="text-none"-->
<!--                    text="Einstellungen"-->
<!--                    prepend-icon="mdi-cog-outline"-->
<!--                    value="chat.settings.index"-->
<!--                    @click="router.visit(route('chat.settings.index'))"-->
<!--                />-->
            </v-tabs>
        </div>

        <slot/>
    </v-container>
</template>
