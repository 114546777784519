<script setup>
import GuestLayout from '@/Layouts/GuestLayout.vue';
import {Head, Link, useForm, usePage} from '@inertiajs/vue3';
import {VBtn, VCard, VCardText, VForm, VImg, VTextField} from "vuetify/components";
import {validationRules} from "@/helper.js";
import {computed, nextTick, ref} from "vue";

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const page = usePage()
const branding = computed(() => page.props.branding)

const loginForm = ref()
const form = useForm({
    email: '',
    password: '',
    remember: false,
});
const isFormValid = ref(false)
const isLoginLoading = ref(false)

const submit = async () => {
    form.clearErrors()
    await loginForm.value.validate()
    if (!isFormValid.value) return

    isLoginLoading.value = true
    await nextTick()

    form.post(route('login'), {
        onSuccess: () => window.gtag('event', 'login'),
        onError: () => isLoginLoading.value = false,
        onFinish: () => form.reset('password'),
    });
};

const errors = computed(() => {
    return {
        ...form.errors,
        ...page.props.errors,
    }
})
</script>

<template>
    <GuestLayout>
        <Head title="Login" />

        <div class="d-flex flex-column flex-grow-1">
            <div class="d-flex flex-column flex-grow-1 align-center justify-center">
                <v-card
                    class="pa-12 pb-8 flex-shrink-1"
                    width="448"
                    rounded="xl"
                >
                    <v-img
                        class="mx-auto mb-8"
                        width="228"
                        :src="branding.logo_url ?? '/img/logo.webp'"
                    />

                    <v-form
                        ref="loginForm"
                        v-model="isFormValid"
                        validate-on="submit"
                        class="d-flex flex-column ga-2"
                        @submit.prevent="submit"
                    >
                        <v-text-field
                            id="email"
                            type="email"
                            v-model="form.email"
                            :rules="[validationRules.required, validationRules.email]"
                            autofocus
                            autocomplete="username"
                            density="compact"
                            label="E-Mail-Adresse"
                            prepend-inner-icon="mdi-email-outline"
                            variant="outlined"
                            :clearable="true"
                            tabindex="1"
                            hide-details="auto"
                            :disabled="isLoginLoading"
                        />

                        <v-text-field
                            id="password"
                            type="password"
                            v-model="form.password"
                            :rules="[validationRules.required]"
                            autocomplete="current-password"
                            density="compact"
                            label="Passwort"
                            prepend-inner-icon="mdi-lock-outline"
                            variant="outlined"
                            :clearable="true"
                            tabindex="2"
                            hide-details="auto"
                            :disabled="isLoginLoading"
                        />

                        <v-checkbox
                            id="remember"
                            v-model="form.remember"
                            label="Eingeloggt bleiben"
                            density="compact"
                            tabindex="3"
                            hide-details="auto"
                            :disabled="isLoginLoading"
                        />

                        <v-alert
                            v-if="Object.keys(errors).length"
                            class="my-5"
                            type="error"
                            :text="Object.values(errors).join('\n')"
                        />

                        <v-btn
                            block
                            size="x-large"
                            color="primary"
                            type="submit"
                            :loading="isLoginLoading"
                            tabindex="4"
                        >
                            Login
                        </v-btn>

                    </v-form>

                    <v-card-text class="text-center d-flex flex-column ga-3">
                        <Link
                            class="text-decoration-none"
                            rel="noopener noreferrer"
                            href="/forgot-password"
                        >
                            Passwort vergessen?
                        </Link>

                        <Link
                            class="text-decoration-none"
                            rel="noopener noreferrer"
                            href="/register"
                        >
                            Noch keinen Account?
                        </Link>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </GuestLayout>
</template>
