<script setup>
import {Head, router, usePage} from '@inertiajs/vue3';
import Default from "@/Layouts/Default.vue";
import {computed, inject, ref} from "vue";
import {openInNewTab} from "@/helper.js";
import {getTypeName} from "@/Helper/payments-helper.js";

defineOptions({layout: Default})
const props = defineProps({
    document: Object,
    preview: String,
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const sendDocumentModal = ref(false)
const deleteDocumentDialog = ref(false)

const sendDocumentLoading = ref(false)
const deleteDocumentLoading = ref(false)

const markDocumentPaidModal = ref(false)
const markDocumentPaidLoading = ref(false)
const markDocumentPaidDate = ref(new Date().toISOString().slice(0, 10))

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {
    title: `${getTypeName(props.document.type)} ${props.document.number || 'Entwurf'}`,
    back: route('payments')
})

function downloadPDF() {
    openInNewTab(route(route().current(), {paymentDocument: props.document.id, type: 'download'}))
}

function deleteDocument() {
    deleteDocumentLoading.value = true
    router.delete(route('payments.delete', {paymentDocument: props.document.id}), {
        onFinish: () => deleteDocumentLoading.value = false
    })
}

function sendDocument() {
    sendDocumentLoading.value = true
    router.post(route('payments.send', {paymentDocument: props.document.id}), undefined, {
        onFinish: () => {
            sendDocumentLoading.value = false
            downloadPDF()
            sendDocumentModal.value = false
        }
    })
}

function markDocumentPaid() {
    markDocumentPaidLoading.value = true
    router.patch(route('payments.mark-paid', {paymentDocument: props.document.id}), {
        date: markDocumentPaidDate.value
    }, {
        onFinish: () => {
            markDocumentPaidModal.value = false
            markDocumentPaidLoading.value = false
        }
    })
}
</script>

<template>
    <Head :title="`${getTypeName(props.document.type)} ${props.document.number || 'Entwurf'} - Zahlung`"/>

    <v-container
        :fluid="true"
        style="width: 1200px; height: 100%"
    >
        <v-row>
            <v-col
                class="d-flex ga-3 align-center justify-end"
            >
                <v-btn
                    text="Vorschau"
                    variant="flat"
                    prepend-icon="mdi-eye-outline"
                    @click="openInNewTab(route(route().current(), {paymentDocument: document.id, type: 'preview'}))"
                />

                <v-btn
                    text="PDF"
                    variant="flat"
                    prepend-icon="mdi-download"
                    @click="downloadPDF"
                />

                <v-btn
                    v-if="user.permissions.includes('update_payments')"
                    class="text-none"
                    text="Bearbeiten"
                    variant="flat"
                    prepend-icon="mdi-pencil"
                    @click="router.visit(route('payments.edit', { paymentDocument: document.id }))"
                />

                <v-btn
                    v-if="document.status === 'draft' && user.permissions.includes('update_payments')"
                    class="text-none"
                    text="Versenden"
                    variant="flat"
                    color="primary"
                    prepend-icon="mdi-send"
                    @click="sendDocumentModal = true"
                />

                <v-btn
                    v-if="document.type === 'invoice' && document.status === 'sent' && user.permissions.includes('update_payments')"
                    class="text-none"
                    text="Bezahlt?"
                    variant="flat"
                    color="green"
                    prepend-icon="mdi-check"
                    @click="markDocumentPaidModal = true"
                />

                <v-btn
                    v-if="user.permissions.includes('delete_payments')"
                    color="red"
                    variant="text"
                    icon="mdi-trash-can-outline"
                    @click="deleteDocumentDialog = true"
                />
            </v-col>
        </v-row>

        <v-row class="h-100 overflow-auto">
            <v-col>
                <div
                    class="bg-white"
                    style="padding: 50px 50px 50px 80px; overflow-y: scroll; height: 100%"
                    v-html="preview"
                />
            </v-col>
        </v-row>

        <v-dialog
            v-model="deleteDocumentDialog"
            :persistent="true"
            width="auto"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ getTypeName(document.type) }} {{ document?.number || 'Entwurf' }} löschen?
                </v-card-title>
                <v-card-text class="d-flex flex-column ga-5">
          <span>
            Möchten Sie {{ getTypeName(document.type) }} {{ document?.number || 'Entwurf' }} wirklich löschen?
          </span>
                    <v-alert
                        type="warning"
                        text="Dieser Vorgang kann nicht rückgängig gemacht werden!"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        class="text-none"
                        variant="tonal"
                        :disabled="deleteDocumentLoading"
                        @click="deleteDocumentDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        class="text-none"
                        color="red-darken-1"
                        prepend-icon="mdi-trash-can-outline"
                        variant="flat"
                        :loading="deleteDocumentLoading"
                        :disabled="deleteDocumentLoading"
                        @click="deleteDocument"
                    >
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="sendDocumentModal"
            width="500px"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Dokument versenden
                </v-card-title>
                <v-card-text>
                    <v-select
                        label="Versandart"
                        :items="[{ title: 'Download (selbst versenden)', value: 'download' }]"
                        model-value="download"
                    />

                    <v-alert
                        type="info"
                        text="Sie versenden das Dokument (PDF) selbst und wir markieren es als versandt."
                        variant="tonal"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        class="text-none"
                        variant="tonal"
                        :disabled="sendDocumentLoading"
                        @click="sendDocumentModal = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        class="text-none"
                        prepend-icon="mdi-download"
                        color="primary"
                        variant="flat"
                        :loading="sendDocumentLoading"
                        :disabled="sendDocumentLoading"
                        @click="sendDocument"
                    >
                        Herunterladen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Document Paid Modal -->
        <v-dialog
            v-model="markDocumentPaidModal"
            width="500px"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Dokument als bezahlt markieren
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="markDocumentPaidDate"
                        variant="outlined"
                        label="Bezahlt am"
                        type="date"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        class="text-none"
                        variant="tonal"
                        :disabled="markDocumentPaidLoading"
                        @click="paidDocumentModal = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        class="text-none"
                        prepend-icon="mdi-check"
                        color="primary"
                        variant="flat"
                        :loading="markDocumentPaidLoading"
                        :disabled="markDocumentPaidLoading"
                        @click="markDocumentPaid"
                    >
                        Als bezahlt markieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
