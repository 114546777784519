<script setup>
import {computed, onBeforeUnmount, onMounted, ref} from 'vue'
import {Head} from "@inertiajs/vue3";

const props = defineProps({status: Number})

const timeout = ref()

const title = computed(() => {
    return {
        503: 'App wird aktualisiert',
        500: 'Server Error',
        404: 'Seite nicht gefunden',
        403: 'Zugriff verweigert',
    }[props.status]
})

const description = computed(() => {
    return {
        503: 'Die App erhält gerade wichtige Updates. Bitte versuche es in ein paar Minuten erneut.',
        500: 'Whoops, irgendwas ist auf unserer Seite schiefgelaufen. Bitte versuche es erneut.',
        404: 'Sorry, wir konnten die Seite nicht finden. Bitte versuche es erneut.',
        403: 'Sorry, aber du bist nicht berechtigt diese Aktion auszuführen.',
    }[props.status]
})

onMounted(() => {
    if (props.status === 503)
        timeout.value = setTimeout(() => location.reload(), 30_000)
})

onBeforeUnmount(() => {
    clearTimeout(timeout.value)
})
</script>

<template>
    <Head :title="title"/>

    <div class="bg-grey-lighten-4 d-flex ga-2 flex-column flex-grow-1 align-center justify-center">
        <span v-if="status === 404" class="text-h1">🕵️‍♂️</span>
        <span v-if="status === 403" class="text-h1">😬</span>
        <span v-if="status === 500" class="text-h1">🙉</span>
        <span v-if="status === 503" class="text-h1">‍🧑‍💻</span>

        <h1>{{ title }}</h1>
        <div>{{ description }}</div>

        <v-progress-circular
            v-if="status === 503"
            indeterminate
        />
    </div>
</template>
