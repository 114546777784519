<script setup>
import {router, usePage} from "@inertiajs/vue3";
import {Companies} from "@/Helper/exclusion-helper.js";
import {computed} from "vue";
import {isAdministrator} from "@/Helper/authorization-helper.js";

const page = usePage()
const user = computed(() => page.props.auth.user)
</script>

<template>
    <v-container
        :fluid="true"
        class="d-flex flex-column ga-3 h-100"
    >
        <div
            v-if="[Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.company.id) && isAdministrator(user)"
            class="d-flex align-center"
        >
            <v-tabs
                :model-value="$route().current()"
                centered
                color="grey-darken-2"
            >
                <v-tab
                    class="text-none"
                    text="Termine"
                    prepend-icon="mdi-calendar-today-outline"
                    value="projects"
                    @click="router.visit(route('projects'))"
                />

                <v-tab
                    class="text-none"
                    text="Auswertung"
                    prepend-icon="mdi-chart-line"
                    value="projects.reveniu-report"
                    @click="router.visit(route('projects.reveniu-report'))"
                />
            </v-tabs>
        </div>

        <slot/>
    </v-container>
</template>
