<template>
  <div class="gradient-bg">
    <div class="gradients-container">
      <div class="g1"></div>
      <div class="g2"></div>
      <div class="g3"></div>
      <div class="g4"></div>
      <!--        <div class="g5"></div>-->
    </div>
  </div>
</template>

<style lang="scss">
@use "/resources/scss/bubbles";
</style>
