<script setup>
import {router} from "@inertiajs/vue3";
import {de} from "date-fns/locale";

const props = defineProps({
    notification: Object
})

function markAsRead() {
    axios.patch(route('notifications.read', {notification: props.notification.id}))
}
</script>

<template>
    <v-list-item
        @click="markAsRead(); router.visit(notification.data.url_path)"
    >
        <div class="d-flex align-center ga-5">
            <div
                v-if="!notification.read_at"
                class="rounded bg-primary flex-shrink-0"
                style="height: 10px; width: 10px;"
            />

            <div class="d-flex flex-column">
                <span class="font-weight-bold">
                    {{ notification.data.title }}
                </span>
                <span>
                    {{ notification.data.subtitle }}
                </span>

                <timeago
                    class="text-caption text-grey-darken-2"
                    :datetime="new Date(notification.created_at)"
                    :locale="de"
                />
            </div>
        </div>
    </v-list-item>
</template>