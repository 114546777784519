<script setup>
import Default from "@/Layouts/Default.vue";
import DetailsWrapper from "@/Pages/Console/Projects/DetailsWrapper.vue";
import draggable from 'vuedraggable';
import {router, useForm} from "@inertiajs/vue3";
import {validationRules} from "@/helper.js";
import {computed, ref} from "vue";
import TaskDetailsProjectDialog from "@/Components/Projects/TaskDetailsProjectDialog.vue";

defineOptions({layout: [Default, DetailsWrapper]})
const props = defineProps({
    project: Object,
    taskGroups: Array,
    taskLists: Array,
})

const taskForm = useForm({
    id: undefined,
    task_group_id: undefined,
    name: undefined
})

const taskGroupForm = useForm({
    id: undefined,
    name: undefined
})

const newTaskGroupForm = ref()
const isNewTaskGroupFormValid = ref(false)
const isTaskDetailDialogOpen = ref(false)

const isTaskDeletionDialogOpen = ref(false)
const isTaskGroupDeletionDialogOpen = ref(false)

const selectedTaskGroupId = ref()
const selectedTaskId = ref()
const selectedTask = computed(() => props.taskGroups.find(taskGroup => taskGroup.id === selectedTaskGroupId.value)?.tasks?.find(task => task.id === selectedTaskId.value))

function groupOnMove(event) {
    return event.relatedContext.index !== 0
}

function groupDragged({moved}) {
    if (moved) {
        const indexItemBefore = props.taskGroups.filter(group => group.id !== moved.element.id)[moved.newIndex - 1]?.index || 0
        const indexItemAfter = props.taskGroups.filter(group => group.id !== moved.element.id)[moved.newIndex]?.index || indexItemBefore + 2_000_000_000

        router.patch(route('projects.details.tasks.update-group', {
                project: props.project.id,
                taskGroup: moved.element.id
            }), {
                index: Math.floor((indexItemBefore + indexItemAfter) / 2)
            }, {
                preserveScroll: true
            }
        )
    }
}

function createTask() {
    taskForm.post(
        route('projects.details.tasks.create', props.project.id),
        {
            preserveScroll: true,
            onSuccess: () => taskForm.reset('name')
        }
    )
}

function taskDragged(group, {
    added,
    moved
}) {
    const action = added || moved

    if (action) {
        const indexItemBefore = group.tasks.filter(task => task.id !== action.element.id)[action.newIndex - 1]?.task_group_index || 0
        const indexItemAfter = group.tasks.filter(task => task.id !== action.element.id)[action.newIndex]?.task_group_index || indexItemBefore + 2_000_000_000

        router.patch(route('projects.details.tasks.update-group-index', {
            project: props.project.id,
            task: action.element.id
        }), {
            task_group_id: group.id !== 0 ? group.id : null,
            task_group_index: Math.floor((indexItemBefore + indexItemAfter) / 2)
        }, {preserveScroll: true})
    }
}

function changeList(taskId, listId) {
    router.patch(route('projects.details.tasks.update-list', {project: props.project.id, task: taskId}), {
        task_list_id: listId
    }, {preserveScroll: true})
}

async function createNewTaskGroup(isModalOpen) {
    await newTaskGroupForm.value.validate()
    if (!isNewTaskGroupFormValid.value) return

    taskGroupForm.post(
        route('projects.details.tasks.create-group', props.project.id),
        {
            preserveScroll: true,
            onSuccess: () => {
                isModalOpen.value = false
                taskGroupForm.reset()
            }
        }
    )
}

function requestTaskDeletion(task) {
    taskForm.reset()
    Object.assign(taskForm, task)
    isTaskDeletionDialogOpen.value = true
}

function deleteTask() {
    router.delete(route('projects.details.tasks.delete', {project: props.project.id, task: taskForm.id}), {
        onSuccess: () => {
            isTaskDeletionDialogOpen.value = false
            taskForm.reset()
        }
    })
}

function requestGroupDeletion(group) {
    taskGroupForm.reset()
    Object.assign(taskGroupForm, group)
    isTaskGroupDeletionDialogOpen.value = true
}

function deleteGroup() {
    router.delete(route('projects.details.tasks.delete-group', {
        project: props.project.id,
        taskGroup: taskGroupForm.id
    }), {
        onSuccess: () => {
            isTaskGroupDeletionDialogOpen.value = false
            taskGroupForm.reset()
        }
    })
}
</script>

<template>
    <v-container class="h-0 flex-grow-1 overflow-hidden" fluid>
        <v-row class="align-center">
            <v-col class="d-flex">
                <v-dialog
                    width="450"
                >
                    <template #activator="{ props }">
                        <v-btn
                            class="text-none"
                            color="primary"
                            prepend-icon="mdi-plus"
                            text="Aufgabengruppe"
                            v-bind="props"
                        />
                    </template>

                    <template #default="{ isActive }">
                        <v-form
                            v-model="isNewTaskGroupFormValid"
                            ref="newTaskGroupForm"
                            validate-on="submit"
                            @submit.prevent="createNewTaskGroup(isActive)"
                        >
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">Neue Aufgabengruppe</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-text-field
                                        v-model="taskGroupForm.name"
                                        variant="outlined"
                                        label="Name"
                                        :autofocus="isActive.value"
                                        :rules="[validationRules.required]"
                                    />
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                        text="Abbrechen"
                                        :disabled="taskGroupForm.processing"
                                        @click="isActive.value = false"
                                    />
                                    <v-btn
                                        color="primary"
                                        text="Erstellen"
                                        type="submit"
                                        :loading="taskGroupForm.processing"
                                        :disabled="taskGroupForm.processing"
                                    />
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </template>
                </v-dialog>
            </v-col>
        </v-row>

        <v-row class="pb-2 h-100 overflow-y-auto">
            <v-col>
                <v-container
                    style="max-width: 1200px"
                    fluid
                    class="h-100 d-flex flex-column flex-nowrap"
                >
                    <draggable
                        :list="taskGroups"
                        item-key="id"
                        group="taskGroups"
                        filter=".drag-disabled"
                        :move="groupOnMove"
                        class="d-flex flex-column ga-3"
                        @change="groupDragged($event)"
                    >
                        <template #item="{ element: group }">
                            <v-row
                                class="flex-grow-0"
                                :class="{ 'drag-disabled': group.id === null }"
                            >
                                <v-col class="d-flex flex-column flex-nowrap">
                                    <div class="d-flex flex-column ga-3">
                                        <div class="d-flex align-center">
                                            <h3>
                                                {{ group?.name }}
                                            </h3>
                                            <v-spacer/>
                                            <v-menu v-if="group.id !== null">
                                                <template #activator="{ props }">
                                                    <v-icon
                                                        icon="mdi-dots-vertical"
                                                        size="small"
                                                        variant="text"
                                                        v-bind="props"
                                                    />
                                                </template>
                                                <v-list>
                                                    <v-list-item
                                                        base-color="red"
                                                        prepend-icon="mdi-trash-can-outline"
                                                        title="Löschen"
                                                        @click="requestGroupDeletion(group)"
                                                    />
                                                </v-list>
                                            </v-menu>
                                        </div>
                                        <v-card
                                            variant="outlined"
                                        >
                                            <v-container :fluid="true">
                                                <v-row>
                                                    <v-col>
                                                        <div class="d-flex flex-column">
                                                            <draggable
                                                                :list="group?.tasks"
                                                                item-key="id"
                                                                group="tasks"
                                                                class="d-flex flex-column"
                                                                @change="taskDragged(group, $event)"
                                                            >
                                                                <template #item="{element: task}">
                                                                    <div
                                                                        class="d-flex flex-column ga-1 cursor-pointer"
                                                                        @click="selectedTaskGroupId = group.id; selectedTaskId = task.id; isTaskDetailDialogOpen = true"
                                                                    >
                                                                        <div class="d-flex ga-3 align-center">
                                                                            <v-menu
                                                                                :open-on-hover="true"
                                                                            >
                                                                                <template #activator="{ props }">
                                                                                    <v-avatar
                                                                                        :color="task.list?.color"
                                                                                        variant="outlined"
                                                                                        v-bind="props"
                                                                                        :text="task.list?.icon"
                                                                                    />
                                                                                </template>

                                                                                <v-list
                                                                                    class="d-flex ga-2 pa-2"
                                                                                    style="border-radius: 30px"
                                                                                >
                                                                                    <v-avatar
                                                                                        v-for="list in taskLists.sort((a, b) => a.index - b.index)"
                                                                                        :color="list?.color"
                                                                                        variant="outlined"
                                                                                        class="cursor-pointer"
                                                                                        @click="changeList(task.id, list.id)"
                                                                                    >
                                                                                        {{ list?.icon }}
                                                                                        <v-tooltip
                                                                                            :key="list.id"
                                                                                            location="bottom"
                                                                                            :text="list?.name"
                                                                                            activator="parent"
                                                                                        />
                                                                                    </v-avatar>
                                                                                </v-list>
                                                                            </v-menu>
                                                                            <span>{{ task.name }}</span>
                                                                            <v-spacer/>
                                                                            <v-menu>
                                                                                <template #activator="{ props }">
                                                                                    <v-icon
                                                                                        icon="mdi-dots-vertical"
                                                                                        size="small"
                                                                                        variant="text"
                                                                                        v-bind="props"
                                                                                    />
                                                                                </template>
                                                                                <v-list>
                                                                                    <v-list-item
                                                                                        base-color="red"
                                                                                        prepend-icon="mdi-trash-can-outline"
                                                                                        title="Löschen"
                                                                                        @click="requestTaskDeletion(task)"
                                                                                    />
                                                                                </v-list>
                                                                            </v-menu>
                                                                        </div>

                                                                        <v-divider
                                                                            class="mb-1"
                                                                        />
                                                                    </div>
                                                                </template>
                                                            </draggable>

                                                            <div
                                                                class="d-flex align-center ga-3"
                                                                @click="taskForm.task_group_id = group.id || null"
                                                            >
                                                                <v-avatar
                                                                    style="border: 1px grey dashed"
                                                                />

                                                                <v-text-field
                                                                    v-if="taskForm?.task_group_id === group?.id"
                                                                    v-model="taskForm.name"
                                                                    class="remove-padding"
                                                                    placeholder="Name"
                                                                    :hide-details="true"
                                                                    variant="plain"
                                                                    :autofocus="true"
                                                                    @keydown.enter="createTask"
                                                                    @keydown.esc="taskForm.reset(); taskForm.task_group_id = undefined"
                                                                    v-click-outside="() => {taskForm.reset(); taskForm.task_group_id = undefined}"
                                                                />

                                                                <span v-else>Neue Aufgabe</span>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </div>
                                </v-col>
                            </v-row>
                        </template>
                    </draggable>
                </v-container>
            </v-col>
        </v-row>

        <!-- Delete Task Dialog -->
        <v-dialog
            v-model="isTaskDeletionDialogOpen"
            :persistent="true"
            width="auto"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ taskForm.name }} löschen?
                </v-card-title>
                <v-card-text>
                    Möchten Sie {{ taskForm.name }} wirklich löschen?<br><b>Dieser Vorgang kann nicht rückgängig
                    gemacht
                    werden!</b>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        :disabled="taskForm.processing"
                        text="Abbrechen"
                        @click="isTaskDeletionDialogOpen = false"
                    />
                    <v-btn
                        color="red-darken-1"
                        :loading="taskForm.processing"
                        :disabled="taskForm.processing"
                        text="Löschen"
                        @click="deleteTask"
                    />
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Delete Group Dialog -->
        <v-dialog
            v-model="isTaskGroupDeletionDialogOpen"
            :persistent="true"
            width="auto"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ taskGroupForm.name }} löschen?
                </v-card-title>
                <v-card-text>
                    Möchten Sie {{ taskGroupForm.name }} wirklich löschen?<br>Alle Aufgaben, die sich in der Gruppe
                    befinden,
                    werden gelöscht.<br><b>Dieser Vorgang kann nicht rückgängig gemacht werden!</b>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        :disabled="taskGroupForm.processing"
                        text="Abbrechen"
                        @click="isTaskGroupDeletionDialogOpen = false"
                    />
                    <v-btn
                        color="red-darken-1"
                        :loading="taskGroupForm.processing"
                        :disabled="taskGroupForm.processing"
                        text="Löschen"
                        @click="deleteGroup"
                    />
                </v-card-actions>
            </v-card>
        </v-dialog>

        <TaskDetailsProjectDialog
            v-model="isTaskDetailDialogOpen"
            :task="selectedTask"
            :project="project"
        />
    </v-container>
</template>
