<script setup>
import {computed} from 'vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import {Head, Link, useForm} from '@inertiajs/vue3';
import {VBtn, VCard, VCardText, VForm, VImg} from "vuetify/components";

const props = defineProps({
    status: {
        type: String,
    },
});

const form = useForm({});

const submit = () => {
    form.post(route('verification.send'));
};

const verificationLinkSent = computed(() => props.status === 'verification-link-sent');
</script>

<template>
    <GuestLayout>
        <Head title="E-Mail Verifizierung"/>

        <div class="d-flex flex-column flex-grow-1">
            <div class="d-flex flex-column flex-grow-1 align-center justify-center">
                <v-card
                    class="pa-12 pb-8 flex-shrink-1"
                    width="448"
                    rounded="xl"
                >
                    <div class="d-flex flex-column align-center ga-3 mb-3 text-center">
                        <span style="font-size: 5em">💌</span>
                        <div>
                            <span>
                                Vielen Dank für deine Registrierung!
                                <br>
                                Bevor es losgehen kann, bitten wir dich, deine E-Mail-Adresse zu verifizieren. Bitte klicke dazu auf den Link, den wir dir per E-Mail gesendet haben.
                                <br>
                                <br>
                                Solltest du keine E-Mail erhalten haben, kannst du auf dem folgenden Link die E-Mail erneut anfordern.
                            </span>
                        </div>

                        <v-alert
                            v-if="verificationLinkSent"
                            type="success"
                        >
                            Eine neue Verifizierungsmail ist unterwegs in dein Postfach 🚀
                        </v-alert>
                    </div>

                    <v-form
                        validate-on="submit"
                        @submit.prevent="submit"
                    >


                        <v-btn
                            block
                            class="text-none"
                            color="primary"
                            size="x-large"
                            type="submit"
                            :disabled="form.processing"
                            :loading="form.processing"
                            tabindex="4"
                        >
                            Verifizierungsmail erneut senden
                        </v-btn>

                    </v-form>

                    <v-card-text class="text-center d-flex flex-column ga-3">
                        <Link
                            class="text-decoration-none"
                            rel="noopener noreferrer"
                            :href="route('logout')"
                            method="post"
                        >
                            Logout
                        </Link>
                    </v-card-text>
                </v-card>
            </div>
        </div>

        <!--        <div class="mb-4 text-sm text-gray-600">-->
        <!--            Thanks for signing up! Before getting started, could you verify your email address by clicking on the link-->
        <!--            we just emailed to you? If you didn't receive the email, we will gladly send you another.-->
        <!--        </div>-->

        <!--        <div class="mb-4 font-medium text-sm text-green-600" v-if="verificationLinkSent">-->
        <!--            A new verification link has been sent to the email address you provided during registration.-->
        <!--        </div>-->

        <!--        <form @submit.prevent="submit">-->
        <!--            <div class="mt-4 flex items-center justify-between">-->
        <!--                <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">-->
        <!--                    Resend Verification Email-->
        <!--                </PrimaryButton>-->

        <!--                <Link-->
        <!--                    :href="route('logout')"-->
        <!--                    method="post"-->
        <!--                    as="button"-->
        <!--                    class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"-->
        <!--                    >Log Out</Link-->
        <!--                >-->
        <!--            </div>-->
        <!--        </form>-->
    </GuestLayout>
</template>
