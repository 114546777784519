<script setup>
import GuestLayout from '@/Layouts/GuestLayout.vue';
import {Head, Link, useForm, usePage} from '@inertiajs/vue3';
import {computed, ref} from "vue";

defineProps({
    status: {
        type: String,
    },
});

const page = usePage()
const branding = computed(() => page.props.branding)

const form = useForm({
    email: '',
});

const showConfirmation = ref(false)

const submit = () => {
    form.post(route('password.email'), {
        onSuccess: () => showConfirmation.value = true
    });
};
</script>

<template>
    <GuestLayout>
        <Head title="Passwort vergessen"/>

        <div class="d-flex flex-column flex-grow-1">
            <div class="d-flex flex-column flex-grow-1 align-center justify-center">
                <v-card
                    class="pa-12 pb-8 flex-shrink-1"
                    width="448"
                    rounded="xl"
                >
                    <div v-if="!showConfirmation">
                        <v-img
                            class="mx-auto mb-8"
                            width="228"
                            :src="branding.logo_url ?? '/img/logo.webp'"
                        />

                        <v-form
                            @submit.prevent="submit"
                        >
                            <v-text-field
                                id="email"
                                type="email"
                                v-model="form.email"
                                required
                                autofocus
                                autocomplete="username"
                                density="compact"
                                label="E-Mail-Adresse"
                                prepend-inner-icon="mdi-email-outline"
                                variant="outlined"
                                :clearable="true"
                                tabindex="1"
                            />

                            <v-btn
                                block
                                class="text-none"
                                color="primary"
                                size="x-large"
                                type="submit"
                                :loading="form.processing"
                                :disabled="form.processing"
                                tabindex="2"
                            >
                                Passwort-Reset anfordern
                            </v-btn>

                        </v-form>
                    </div>

                    <div v-else class="d-flex flex-column align-center ga-3 text-center">
                        <span style="font-size: 5em">💌</span>
                        <div>
                            <span>Wir haben Ihnen eine Bestätigung per E-Mail gesendet. Bitte befolgen Sie die Anweisungen dort, um Ihr Passwort zurückzusetzen.</span>
                            <br>
                            <br>
                            <span>Bitte sehen Sie auch im Spam-Ordner nach.</span>
                        </div>
                    </div>

                    <v-card-text class="text-center d-flex flex-column ga-3">
                        <Link
                            class="text-decoration-none"
                            rel="noopener noreferrer"
                            href="/login"
                        >Zum Login
                        </Link>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </GuestLayout>
</template>
