<script setup>
import {isAudio, isExcel, isImage, isPDF, isText, isVideo, isWord, isZip} from "@/Helper/file-helper.js";

const props = defineProps({
    file: Object,
    size: String
})

function getIconName() {
    switch (true) {
        case props.file.is_folder:
            return 'mdi-folder-outline'

        case isImage(props.file):
            return 'mdi-file-image-outline'

        case isAudio(props.file):
            return 'mdi-file-music-outline'

        case isVideo(props.file):
            return 'mdi-file-video-outline'

        case isWord(props.file):
            return 'mdi-file-word-outline'

        case isExcel(props.file):
            return 'mdi-file-excel-outline'

        case isZip(props.file):
            return 'mdi-folder-zip-outline'

        case isText(props.file):
            return 'mdi-file-document-outline'

        case isPDF(props.file):
        default:
            return 'mdi-file-outline'
    }
}

</script>

<template>
    <v-icon :icon="getIconName()" :size="size ?? 'large'"/>
</template>

<style scoped>

</style>
