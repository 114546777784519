<script setup>
import Default from "@/Layouts/Default.vue";
import {Head, router, usePage} from "@inertiajs/vue3";
import {computed, inject, ref, watch} from "vue";
import Wrapper from "@/Pages/Console/Projects/Wrapper.vue";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip
} from 'chart.js'
import {Bar, Pie} from 'vue-chartjs'
import 'hw-chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import {Aspect6} from 'hw-chartjs-plugin-colorschemes/src/colorschemes/colorschemes.office';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Tooltip,
    Legend
)

defineOptions({layout: [Default, Wrapper]})
const props = defineProps({
    projects: Array,
    tasksCreated: Array,
    taskStatus: Array,
    tasksWithDeadline: Array,
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {title: 'Auswertung'})

const selectedProjects = ref(route().params.projects?.split(',') ?? [])
watch(selectedProjects, newSelectedProjects => {
    router.reload({
        data: {
            projects: newSelectedProjects.length ? newSelectedProjects?.join(',') : undefined
        },
        preserveState: false
    })
})

const selectedDateRange = ref(route().params.start && route().params.end ? [new Date(parseInt(route().params.start)), new Date(parseInt(route().params.end))] : [new Date(), new Date()])
watch(selectedDateRange, newSelectedDateRange => {
    console.log(newSelectedDateRange)

    let startUnix = undefined
    let endUnix = undefined

    if (newSelectedDateRange && newSelectedDateRange.length > 0) {
        startUnix = newSelectedDateRange[0].getTime()
        const endDateTime = newSelectedDateRange[newSelectedDateRange.length - 1]
        endDateTime.setHours(23, 59, 59, 999)
        endUnix = endDateTime.getTime()
    }

    router.reload({
        data: {
            start: startUnix,
            end: endUnix
        },
        preserveState: false
    })
})

const allProjectsSelected = computed(() => selectedProjects.value.length === props.projects.length)
const someProjectsSelected = computed(() => selectedProjects.value.length > 0)

const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
        colorschemes: {
            scheme: Aspect6
        }
    }
}

function toggleAllProjects() {
    if (allProjectsSelected.value) {
        selectedProjects.value = []
    } else {
        selectedProjects.value = props.projects.map(p => p.id)
    }
}
</script>

<template>
    <Head title="Auswertung"/>

    <v-container
        :fluid="true"
        class="d-flex flex-column w-100"
    >
        <v-row>
            <v-col>
                <v-select
                    v-model="selectedProjects"
                    :items="projects.map(project => { return { title: project.name, value: project.id } })"
                    label="Projekt(e)"
                    variant="outlined"
                    multiple
                    chips
                    closable-chips
                >
                    <template v-slot:prepend-item>
                        <v-list-item
                            title="Alle Projekte"
                            @click="toggleAllProjects"
                        >
                            <template v-slot:prepend>
                                <v-checkbox-btn
                                    :indeterminate="someProjectsSelected && !allProjectsSelected"
                                    :model-value="allProjectsSelected"
                                ></v-checkbox-btn>
                            </template>
                        </v-list-item>

                        <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
            </v-col>

            <v-col>
                <v-date-input
                    v-model="selectedDateRange"
                    label="Zeitraum"
                    multiple="range"
                    variant="outlined"
                    placeholder=""
                    prepend-icon=""
                    clearable
                    persistent-clear
                    @click:clear="selectedDateRange = undefined"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-row>
                    <v-col>
                        <v-card class="pa-5">
                            <h2>Erstellt</h2>

                            <Bar
                                :data="{
                            labels: tasksCreated.map(tc => tc.date),
                            datasets: [{
                                label: 'Termine',
                                data: tasksCreated.map(tc => tc.count),
                            }]
                        }"
                                :options="chartOptions"
                            />
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-card class="pa-5">
                            <h2>Frist</h2>

                            <Bar
                                :data="{
                                    labels: tasksWithDeadline.map(twd => twd.deadline),
                                    datasets: [{
                                        label: 'Termine',
                                        data: tasksWithDeadline.map(twd => twd.count),
                                    }]
                                }"
                                :options="chartOptions"
                            />
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>

            <v-col>
                <v-card class="pa-5">
                    <h2>Aktueller Status</h2>

                    <Pie
                        :data="{
                            labels: taskStatus.map(ts => ts.status),
                            datasets: [{
                                data: taskStatus.map(ts => ts.count),
                            }]
                        }"
                        :options="chartOptions"
                    />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
