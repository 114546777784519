<script setup>
import {Head, useForm} from "@inertiajs/vue3";
import Default from "@/Layouts/Default.vue";
import {validationRules} from "@/helper.js";
import {vMaska} from "maska/vue";
import {inject, ref} from "vue";
import {getTypeName} from "@/Helper/payments-helper.js";
import {Mask} from "maska";

defineOptions({layout: Default})
const props = defineProps({
    document: Object,
    customers: Array,
    projects: Array,
})

const currentDate = new Date().toISOString().slice(0, 10)
const floatMask = new Mask({
    mask: '0,99',
    tokens: {0: {pattern: /[0-9]/, multiple: true}, 9: {pattern: /[0-9]/, optional: true}}
})

const documentForm = ref()
const isDocumentFormValid = ref(false)

const form = useForm({
    id: props.document?.id,
    type: props.document?.type || route().params.type,
    customer_id: props.document?.customer_id || route().params.customer,
    project_id: props.document?.project_id,
    date: props.document?.date || currentDate,
    performance_period_start: props.document?.performance_period_start || currentDate,
    performance_period_end: props.document?.performance_period_end || currentDate,
    items: props.document?.items || [{ tax_rate: 1900 }],
    notes: props.document?.notes,
    discount: props.document?.discount,
    discount_type: props.document?.discount_type || 'percent',
})

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {
    title: `${getTypeName(props.document?.type || route().params.type)} ${props.document ? props.document.number || 'Entwurf' : ''}`,
    back: route('payments')
})

function resetAndClose() {
    form.reset()
}

function getFloatInt(floatValueString) {
    return parseInt(parseFloat(floatValueString.replace(',', '.')).toFixed(2).replace('.', ''))
}

async function submit() {
    await documentForm.value.validate()
    if (!isDocumentFormValid.value) return

    if (form.data().id) {
        form.put(route('payments.documents.update'), {
            onSuccess: () => resetAndClose()
        })
    } else {
        form.post(route('payments.documents.create'), {
            onSuccess: () => resetAndClose()
        })
    }
}
</script>

<template>
    <Head :title="`${getTypeName(props.document?.type || route().params.type)} ${props.document ? props.document.number || 'Entwurf' : ''} - Zahlung`" />

    <v-container
        :fluid="true"
        style="max-width: 1200px"
    >
        <v-row>
            <v-col class="text-end">
                <v-btn
                    class="text-none"
                    prepend-icon="mdi-content-save-outline"
                    text="Speichern"
                    color="primary"
                    :loading="form.processing"
                    @click="submit"
                />
            </v-col>
        </v-row>

        <v-form
            v-model="isDocumentFormValid"
            ref="documentForm"
            validate-on="submit"
        >
            <v-row>
                <v-col>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="form.customer_id"
                                @update:model-value="form.project_id = null"
                                label="Kunde"
                                :items="customers.map(customer => { return { title: customer.name, value: customer.id } })"
                                variant="outlined"
                                :rules="[validationRules.required]"
                            />
                        </v-col>

                        <v-col>
                            <v-select
                                v-model="form.project_id"
                                :disabled="!form.customer_id"
                                label="Projekt"
                                :items="[{ title: 'Kein Projekt', value: null }, ...projects.filter(project => project.customer.id === form.customer_id).map(project => { return { title: project.name, value: project.id } })]"
                                variant="outlined"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="form.date"
                                label="Datum"
                                type="date"
                                variant="outlined"
                                :rules="[validationRules.required]"
                            />
                        </v-col>

                        <v-col>
                            <v-text-field
                                v-model="form.performance_period_start"
                                label="Leistungszeitraum Start"
                                type="date"
                                variant="outlined"
                                :rules="[validationRules.required]"
                            />
                        </v-col>

                        <v-col>
                            <v-text-field
                                v-model="form.performance_period_end"
                                label="Leistungszeitraum Ende"
                                type="date"
                                variant="outlined"
                                :rules="[validationRules.required]"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <h3>
                                {{ getTypeName(form.type) }}spositionen
                            </h3>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-row
                                v-for="(item, itemIndex) in form.items"
                                :key="item.id"
                            >
                                <v-col class="d-flex ga-2">
                                    <v-text-field
                                        v-model="item.title"
                                        label="Name"
                                        variant="outlined"
                                        :hide-details="true"
                                        density="compact"
                                        :rules="[validationRules.required]"
                                    />

                                    <v-text-field
                                        v-maska="floatMask.opts"
                                        :model-value="floatMask.masked((item.quantity / 100).toString().replace('.', ','))"
                                        @maska="value => item.quantity = getFloatInt(value.detail.masked)"
                                        type="text"
                                        inputmode="numeric"
                                        label="Anzahl"
                                        variant="outlined"
                                        :hide-details="true"
                                        density="compact"
                                        class="flex-grow-0"
                                        style="width: 105px"
                                        :rules="[validationRules.required]"
                                    />

                                    <v-text-field
                                        v-model="item.units"
                                        label="Einheit"
                                        type="text"
                                        variant="outlined"
                                        :hide-details="true"
                                        density="compact"
                                        class="flex-grow-0"
                                        style="width: 95px"
                                        :rules="[validationRules.required]"
                                    />

                                    <v-text-field
                                        v-maska="floatMask.opts"
                                        :model-value="floatMask.masked((item.price / 100).toString().replace('.', ','))"
                                        @maska="value => item.price = getFloatInt(value.detail.masked)"
                                        label="Preis"
                                        variant="outlined"
                                        :hide-details="true"
                                        density="compact"
                                        class="flex-grow-0"
                                        style="width: 120px"
                                        :rules="[validationRules.required]"
                                    >
                                        <template #append-inner>
                                            <v-label>€</v-label>
                                        </template>
                                    </v-text-field>

                                    <v-select
                                        v-model="item.tax_rate"
                                        label="Steuer"
                                        :items="[{ title: 'frei', value: 0 }, { title: '7 %', value: 700 }, { title: '19 %', value: 1900 }]"
                                        variant="outlined"
                                        :hide-details="true"
                                        density="compact"
                                        style="width: 111px"
                                        class="flex-grow-0"
                                    />

                                    <div class="d-flex align-center">
                                        <v-icon
                                            :disabled="form.items.length <= 1"
                                            icon="mdi-trash-can-outline"
                                            size="small"
                                            @click="form.items.splice(itemIndex, 1)"
                                        />
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-btn
                                        class="text-none"
                                        prepend-icon="mdi-plus"
                                        text="Position hinzufügen"
                                        variant="text"
                                        @click="form.items.push({ tax_rate: 1900 })"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-textarea
                                v-model="form.notes"
                                label="Anmerkungen"
                                variant="outlined"
                                :hide-details="true"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col
                            cols="3"
                            class="d-flex ga-3"
                        >
                            <v-text-field
                                v-maska="floatMask.opts"
                                :model-value="floatMask.masked((form.discount / 100).toString().replace('.', ','))"
                                @maska="value => form.discount = getFloatInt(value.detail.masked)"
                                label="Rabatt"
                                variant="outlined"
                                :hide-details="true"
                                style="max-width: 120px"
                                :rules="[form.discount_type === 'percent' ? validationRules.max100Percent : true]"
                            />

                            <v-select
                                v-model="form.discount_type"
                                class="flex-grow-0"
                                :items="[{ title: '€', value: 'money' }, { title: '%', value: 'percent' }]"
                                variant="outlined"
                                :hide-details="true"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
