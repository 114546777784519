<script setup>
import {Head, Link, router, usePage} from '@inertiajs/vue3';
import Default from "@/Layouts/Default.vue";
import NewEditCustomerDialog from "@/Components/Customers/NewEditCustomerDialog.vue";
import {computed, inject, ref} from "vue";

defineOptions({layout: Default})
defineProps({customers: Object})
const emit = defineEmits(['test'])

const tableHeaders = [
    {title: 'Kunde', key: 'name'},
    {title: 'Kundennummer', key: 'number'},
    {title: 'Ansprechpartner', key: 'contact_person'},
    {title: 'Kontakt', key: 'contact'},
    {title: 'Notizen', key: 'notes', width: 250},
]

const page = usePage()
const user = computed(() => page.props.auth.user)

const isNewEditCustomerDialogOpen = ref(false)

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {title: 'Kunden'})
</script>

<template>
    <Head title="Kunden"/>

    <v-container
        fluid
        class="overflow-hidden h-100 d-flex flex-column flex-nowrap"
    >
        <v-row class="flex-grow-0 align-center">
            <v-col class="d-flex">
                <v-btn
                    v-if="user.permissions.includes('create_customers')"
                    color="primary"
                    prepend-icon="mdi-plus"
                    text="Kunde"
                    @click="isNewEditCustomerDialogOpen = true"
                />
                <v-spacer/>
                <Link
                    v-if="user.permissions.includes('access_customers_settings')"
                    :href="$route('customers.settings')"
                    style="text-decoration: none; color: inherit"
                >
                    <v-icon icon="mdi-cog-outline"/>
                </Link>
            </v-col>
        </v-row>

        <v-container
            style="max-width: 1200px"
            class="h-100 d-flex flex-column flex-nowrap"
        >
            <v-row v-if="!customers.data?.length">
                <v-col class="d-flex flex-column flex-grow-1 align-center justify-center ga-3">
                    <h1>Noch keine Kunden 😅</h1>
                    <img
                        :src="`/img/customers/no_customers.svg`"
                        style="height: 300px"
                        alt=""
                    >
                    <div>
                        <v-btn
                            v-if="user.permissions.includes('create_customers')"
                            color="primary"
                            prepend-icon="mdi-plus"
                            text="Ersten Kunden anlegen"
                            @click="isNewEditCustomerDialogOpen = true"
                        />
                    </div>
                </v-col>
            </v-row>
            <v-card
                v-if="customers.data?.length"
                class="h-100 overflow-y-auto flex-grow-1"
            >
                <v-container
                    :fluid="true"
                    class="d-flex flex-column flex-nowrap"
                >
                    <v-row>
                        <v-col
                            style="overflow-y: scroll"
                        >
                            <v-data-table
                                :items="customers.data"
                                :headers="tableHeaders"
                                :items-per-page="0"
                                @click:row="(_, {item}) => router.visit(`/customers/${item.id}`)"
                            >

                                <template #item.name="{ item }">
                                    <div class="d-flex ga-3 align-center pa-2">
                                        <v-img
                                            height="80"
                                            min-width="120"
                                            max-width="120"
                                            :src="item.logo_url || '/img/customers/building.svg'"
                                        />

                                        <div class="d-flex flex-column">
                                            <div class="font-weight-bold">{{ item.name }}</div>
                                            <div class="text-caption text-grey-darken-1">
                                                {{ item.address_city }}
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template #item.contact_person="{ item }">
                                    <div class="d-flex ga-3">
                                        <div class="d-flex flex-column">
                                            <div class="font-weight-bold">{{ item.contact_firstname }}
                                                {{ item.contact_lastname }}
                                            </div>
                                            <div class="text-caption text-grey-darken-1">
                                                {{ item.contact_email }}
                                            </div>
                                            <div class="text-caption text-grey-darken-1">
                                                {{ item.contact_phone_number }}
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template #item.contact="{ item }">
                                    <div class="d-flex ga-3">
                                        <div class="d-flex flex-column">
                                            <div
                                                v-if="item.email"
                                                class="text-caption text-grey-darken-1"
                                            >
                                                {{ item.email }}
                                            </div>
                                            <div
                                                v-if="item.phone_number"
                                                class="text-caption text-grey-darken-1"
                                            >
                                                {{ item.phone_number }}
                                            </div>
                                            <div
                                                v-if="item.website"
                                                class="text-caption text-grey-darken-1"
                                                @click.prevent="window.open(item.website, '_blank')"
                                            >
                                                {{ item.website }}
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template #item.notes="{ item }">
                                    <div style="display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 4; overflow: hidden">
                                        {{item.notes}}
                                    </div>
                                </template>

                                <template #bottom/>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-container>

        <NewEditCustomerDialog
            :is-open="isNewEditCustomerDialogOpen"
            @abort-clicked="isNewEditCustomerDialogOpen = false"
        />
    </v-container>
</template>
