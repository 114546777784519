<script setup>

import {ref} from "vue";
import {useForm} from "@inertiajs/vue3";
import {validationRules} from "@/helper.js";
import {vMaska} from "maska/vue";

const props = defineProps({
    modelValue: Boolean,
    project: {
        type: Object,
        default: {}
    },
    customers: Array,
    team: Array,
    customerId: String
})
const emit = defineEmits([
    'close',
    'update:modelValue'
])
const projectForm = ref()
const isFormValid = ref(false)

const form = useForm({
    id: props.project.id,
    name: props.project.name,
    description: props.project.description,
    billable: props.project.billable,
    budget: props.project.budget,
    budget_type: props.project.budget_type,
    start: props.project.start,
    end: props.project.end,
    customer_id: props.project.customer_id || props.customerId,
    members: props.project.members?.map(member => member.id)
})

const budgetOptions = {mask: '9.99#', tokens: {9: {pattern: '[0-9]', repeated: true}}, reversed: true}

function resetAndClose() {
    form.reset()
    emit('close')
}

async function submit() {
    await projectForm.value.validate()
    if (!isFormValid.value) return

    if (form.data().id) {
        form.put(route('projects.details.update', { project: props.project.id }), {
            preserveState: false,
            onSuccess: () => resetAndClose()
        })
    } else {
        form.post(route('projects.create'), {
            preserveState: false,
            onSuccess: () => resetAndClose()
        })
    }
}
</script>

<template>
    <v-dialog
        :model-value="modelValue"
        @update:modelValue="newValue => $emit('update:modelValue', newValue)"
        max-width="1000"
    >
        <v-form
            v-model="isFormValid"
            ref="projectForm"
            validate-on="submit"
            @submit.prevent="submit"
        >
            <v-card
                :title="form.id ? form.name : 'Neues Projekt'"
            >
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-row>
                                <v-col>
                                    <h4>Allgemein</h4>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="form.name"
                                        label="Name"
                                        type="text"
                                        variant="outlined"
                                        :autofocus="true"
                                        :rules="[validationRules.required]"
                                    />

                                    <v-select
                                        v-model="form.customer_id"
                                        label="Kunde"
                                        :items="customers.map(customer => { return { title: customer.name, value: customer.id } })"
                                        variant="outlined"
                                        :rules="[validationRules.required]"
                                    />

                                    <v-autocomplete
                                        v-model="form.members"
                                        :items="team.map(member => { return { title: `${member.firstname} ${member.lastname}`, value: member.id } })"
                                        label="Team"
                                        :multiple="true"
                                        variant="outlined"
                                    >
                                        <template #selection="{ item, index }">
                                            <v-chip v-if="index < 2">
                                                <span>{{ item.title }}</span>
                                            </v-chip>
                                            <span
                                                v-if="index === 2"
                                                class="text-grey text-caption align-self-center"
                                            >
                          (+{{ form.members?.length - 2 }} others)
                        </span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col>
                                    <v-textarea
                                        v-model="form.description"
                                        rows="8"
                                        :hide-details="true"
                                        label="Beschreibung"
                                        variant="outlined"
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <h4>Zeitraum</h4>
                                </v-col>

                                <v-col>
                                    <h4>Budget und Abrechnung</h4>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-row>
                                        <v-col
                                            class="pb-0"
                                        >
                                            <v-menu :close-on-content-click="false">
                                                <template v-slot:activator="{ props }">
                                                    <v-text-field
                                                        label="von"
                                                        :model-value="form.start ? new Date(form.start)?.toLocaleDateString(undefined, {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric'
                                                        }) : undefined"
                                                        readonly
                                                        v-bind="props"
                                                        variant="outlined"
                                                        hide-details
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    :model-value="form.start ? new Date(form.start) : new Date()"
                                                    @update:modelValue="value => {
                                                        value.setHours(12)
                                                        form.start = value.toISOString().slice(0, 10)
                                                    }"
                                                    color="primary"
                                                />
                                            </v-menu>
                                        </v-col>

                                        <v-col
                                            class="pb-0"
                                        >
                                            <v-menu :close-on-content-click="false">
                                                <template v-slot:activator="{ props }">
                                                    <v-text-field
                                                        label="bis"
                                                        :model-value="form.end ? new Date(form.end)?.toLocaleDateString(undefined, {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric'
                                                        }) : undefined"
                                                        readonly
                                                        v-bind="props"
                                                        variant="outlined"
                                                        hide-details
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    :model-value="form.end ? new Date(form.end) : new Date()"
                                                    @update:modelValue="value => {
                                                        value.setHours(12)
                                                        form.end = value.toISOString().slice(0, 10)
                                                    }"
                                                    color="primary"
                                                />
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col>
                                    <v-row>
                                        <v-col>
                                            <v-switch
                                                v-model="form.billable"
                                                label="Abrechenbar"
                                                color="primary"
                                                :inset="true"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            class="py-0"
                                        >
                                            <v-select
                                                v-model="form.budget_type"
                                                label="Budget-Typ"
                                                :items="[{ title: 'Geld', value: 'money' }, { title: 'Arbeitszeit', value: 'time' }]"
                                                variant="outlined"
                                            />
                                        </v-col>

                                        <v-col
                                            class="py-0"
                                        >
                                            <v-text-field
                                                :model-value="form.budget"
                                                v-maska="budgetOptions"
                                                @maska="form.budget = $event.detail.unmasked"
                                                label="Budget"
                                                :hide-spin-buttons="true"
                                                variant="outlined"
                                            >
                                                <template

                                                    #append-inner
                                                >
                                                    <label v-if="form.budget_type === 'money'">
                                                        €
                                                    </label>

                                                    <label v-if="form.budget_type === 'time'">
                                                        Stunden
                                                    </label>
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        text="Abbrechen"
                        :disabled="form.processing"
                        @click="$emit('close')"
                    />
                    <v-btn
                        class="text-none"
                        text="Speichern"
                        color="primary"
                        :loading="form.processing"
                        type="submit"
                    />
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
